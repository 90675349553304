import React from "react";
import { withTranslation } from 'react-i18next';
import { Grid, TextField } from "@material-ui/core";
import { useEffect } from "react";

function ProductAmount(props) {
  const {data, addQuantityToProduct, effecten, selectedQuantities} = props;
  const [current_quantity, setCurrentQuantity] = React.useState('null');

  useEffect(() => {
    if (selectedQuantities.length > 0 && JSON.stringify(selectedQuantities) !== JSON.stringify(current_quantity) ) {
      if (selectedQuantities.find(p => p.photo_id === data.id) !== undefined) {
        setCurrentQuantity(selectedQuantities.find(p => p.photo_id === data.id).quantity)
      }
    }
  }, [selectedQuantities, data.id, current_quantity])

  return (
    <Grid container direction="row">
      <TextField
        type="select"
        key={data.length !== 0 ? data.id : 0}
        id={data.length !== 0 ? data.id : 0}
        select
        SelectProps={{ native: true }}
        label={props.t("products:Quantity")}
        value={current_quantity}
        onChange={(e) =>
          addQuantityToProduct(e, effecten.product_id, data.id)
        }
        InputLabelProps={{ shrink: true }}
        className="mb-2"
        variant="standard"
        fullWidth
      >
          <option id={0} value={"null"}>
            {props.t("products:Choose quantity")}
          </option>
          {[...Array(10)].map((value, index) => {
              return <option id={index + 1} key={index} value={index}>
                {index + 1}
              </option>
          })}
    </TextField>
    </Grid>
  );
}

export default React.memo(withTranslation('products')(ProductAmount));