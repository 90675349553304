import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useThemeData } from "../../context/ThemeDataContext";
import GeneralButton from "../Buttons";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PopUpComponent(props) {
  const { background_color_7, background_color_2 } = useThemeData().themeData.site.theme;
  const { photographerType } = useThemeData();
  const background_color = photographerType === 'equestrianphotoservice' ? background_color_7 : background_color_2;
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        fullWidth
        fullScreen={isMobile}
        onClose={props.closePopup}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.closePopup}>
          {props.btn1 ? (
              <GeneralButton
                  to="/cart"
                  style={{
                    margin: "0rem 1rem",
                    backgroundColor: `${background_color}`,
                    borderColor: `${background_color}`,
                  }}
                  className="btn btn-light align-around"
                  name="addToCart"
                  endIcon={<ShoppingCartIcon color="inherit" />}
              >
                {props.btn1}
              </GeneralButton>
          ) : null}
          <GeneralButton
            className="btn btn-light align-around"
            onClick={props.closePopup}
            style={{
              backgroundColor: `${background_color}`,
              borderColor: `${background_color}`
          }}
          >
            {props.btn2}
          </GeneralButton>
        </DialogTitle>
        <DialogContent dividers>
          {props.text && <div>{props.text}</div>}
          {props.content && <div>{props.content}</div>}
        </DialogContent>
        {props.btn1 ? (
          <DialogActions>
            {props.btn1 ? (
                <GeneralButton
                    to="/cart"
                    style={{
                      margin: "0rem 1rem",
                      backgroundColor: `${background_color}`,
                      borderColor: `${background_color}`,
                    }}
                    className="btn btn-light align-around"
                    name="addToCart"
                    endIcon={<ShoppingCartIcon color="inherit" />}
                >
                  {props.btn1}
                </GeneralButton>
            ) : null}
            <GeneralButton
              className="btn btn-light align-around"
              onClick={props.closePopup}
              style={{
                backgroundColor: `${background_color}`,
                borderColor: `${background_color}`
            }}
            >
              {props.btn2}
            </GeneralButton>
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
}
