/**
 * PhotoUploadComponent
 * This component handles the photo upload functionality including file validations,
 * mutation calls to upload files, and redirection based on the upload status.
 *
 * @component
 * @param {Object} props Component properties
 * @param {number} props.folder_id Folder ID for photo upload
 * @param {string} props.type Type of upload
 * @param {Function} props.t Translation function from i18next
 * @param {Object} props.location React Router location object
 */

// Importing React and necessary hooks
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid, TextField, FormGroup, Card, CardContent } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withTranslation } from 'react-i18next';
import {usePhotoBaseData} from "../../context/PhotoBasicDataContext";
// Importing local resources and components
import { UPLOAD_FILE } from '../../queries';
import { loginArrayFromStorage } from "../../services/auth.service";
import { useThemeData } from '../../context/ThemeDataContext';
import GeneralButton from "../Buttons";
import {useHistory} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";
import loadImage from "../../loader.svg";

function PhotoUploadComponent({ folder_id, type, t, location }) {
    // Extracting theme data using context
    const { site_id } = useThemeData().themeData.site;
    const { photographerType } = useThemeData();
    const { font_family, primary_color_2 } = useThemeData().themeData.site.theme;

    // Access refetch function from PhotoBasicDataProvider
    const { refetchPhotos } = usePhotoBaseData();

    // State for managing file upload details
    const [Message, setMessage] = useState({ successMessage: false });
    const [filename, setFilename] = useState('no image');
    const [newFolderId, setNewFolderId] = useState('no image');
    const [newId, setNewId] = useState('no image');

    // Retrieving photographer's details from local storage
    const login_name = photographerType === 'printservice' ? photographerType : localStorage.getItem('login_name');
    const login_email = localStorage.getItem('login_email');
    const logins = JSON.parse(login_name);
    const firstLogin = logins[0];
    const history = useHistory();

    // Apollo mutation for uploading files
    const [singleUpload, { loading: loadingSingleUpload, error: errorSingleUpload }] = useMutation(UPLOAD_FILE, {
        onCompleted: (e) => {
            setFilename(e.singleUpload.filename);
            setNewFolderId(e.singleUpload.folder_id);
            setNewId(e.singleUpload.id);
            setMessage({ successMessage: true });
            loginArrayFromStorage(e.singleUpload.login_name, 'login_name');


            // // Reload PhotoBasicDataProvider
            // refetchPhotos().then(() => {
            //     console.log("PhotoBasicDataProvider reloaded successfully");
            // }).catch(err => {
            //     console.error("Error reloading PhotoBasicDataProvider:", err);
            // });

            window.location.href = "/shoppage/photo_id/"+e.singleUpload.id;

        }
    });

    // Redirect based on the upload status
    const goBackHome = () => {
        history.push(`/shoppage/photo_id/${newId}`);
    }

    // Handling loading and error states
    if (loadingSingleUpload) return <p>{t('Loading...')}</p>;
    if (errorSingleUpload) return <p>{t('Looks like we have a problem...')}</p>;

    // Render component UI
    return (
        <Grid item lg={12} md={6} style={{ fontFamily: font_family, color: primary_color_2 }}>
            <Card style={{ fontFamily: font_family, color: primary_color_2 }}>
                <CardContent className="contact-text">
                    {Message.successMessage && <Alert severity="success">{t('photoUpload:Photo ready')}</Alert>}
                    <Grid container spacing={1} direction="row" className="justify-content-center">
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <form className="contact-text" onSubmit={() => { console.log("Submitted") }} encType={'multipart/form-data'}>
                                <p>{t('photoUpload:Products')}</p>
                                <FormGroup>
                                    <TextField
                                        name='document'
                                        type='file'
                                        onChange={({ target: { files } }) => {
                                            const file = files[0];
                                            if (file && file.size >= 204800 && file.size <= 5120000) {
                                                singleUpload({
                                                    variables: {
                                                        file,
                                                        login_name: firstLogin,
                                                        login_email,
                                                        site_id,
                                                        folder_id
                                                    }
                                                });
                                            } else {
                                                alert('De foto moet minimaal 200KB zijn en maximaal 5MB.');
                                            }
                                        }}
                                    />
                                </FormGroup>
                                <GeneralButton onClick={goBackHome} className="btn btn-light btn-margin">
                                    {t('photoUpload:Back to the Shop')}
                                </GeneralButton>
                            </form>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {filename !== 'no image' && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <LazyLoadImage
                                        src={`${process.env.REACT_APP_PHOTO_URL}${site_id}/${newFolderId}/0/0/0/0/${newId}/${filename}`}
                                        alt={filename}
                                        style={{
                                            height: 'auto',
                                            width: '50%',
                                            display: 'block',
                                        }}
                                        placeholderSrc={loadImage}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default React.memo(withTranslation(['message', 'photoUpload'])(PhotoUploadComponent));

