import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Grid, CardContent, CardActions, Typography, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useThemeData } from '../../context/ThemeDataContext';
import GeneralButton from "../Buttons";

function DiscountBar(props) {
    const { background_color_7, background_color_4, cart_header_text } = useThemeData().themeData.site.theme;

    const [discount_code, setDiscountCode] = useState("");
    const inputRef = useRef(null); // Create a ref for the TextField

    const onTextChange = (e) => {
        const valid_value = e.target.value.replace(/\s/g, "");
        setDiscountCode(valid_value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default behavior (scrolling)
            props.handleDiscount(e, props.dataCart, discount_code);

            // Refocus the input after handling the event
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    return (
        <Grid item lg={4} sm={4} xs={12}>
            <Card className="col-lg-4 col-xs-12 col-md-12">
                <CardContent
                    className="row border-bottom-small"
                    style={{
                        backgroundColor: `${background_color_7}`,
                        borderColor: `${background_color_4}`,
                        color: `${cart_header_text}`
                    }}
                >
                    <Typography variant="h6" align="left" className="col-12 subtitle">
                        {props.t('shoppingcart:Discountcode')}
                    </Typography>
                </CardContent>
                <CardActions className="row border-bottom-small" style={{
                    borderColor: `${background_color_4}`
                }}>
                    <TextField
                        value={discount_code}
                        onChange={onTextChange}
                        onKeyDown={handleKeyDown}
                        type="text"
                        name="discount_code"
                        placeholder={props.t('shoppingcart:Your discount code')}
                        id="outlined-basic"
                        label={props.t('shoppingcart:Discountcode')}
                        variant="outlined"
                        style={{
                            borderColor: `${background_color_4}`,
                            width: `90%`
                        }}
                        inputRef={inputRef} // Attach the ref to the TextField
                    />
                    {props.Messages.discountCodeMessage && <Alert className="align-self-center width-fill"
                                                                  severity="success">{props.t('shoppingcart:The discountcode has been added to your shoppingcart')}</Alert>}
                    {props.Messages.discountCodeUsedMessage && <Alert className="align-self-center width-fill"
                                                                      severity="warning">{props.t('shoppingcart:This discountcode is already active')}</Alert>}
                    {props.Messages.discountCodeExistMessage && <Alert className="align-self-center width-fill"
                                                                       severity="warning">{props.t('shoppingcart:This discountcode is already active')}</Alert>}
                    {props.Messages.discountCodeFailedMessage && <Alert className="align-self-center width-fill"
                                                                        severity="error">{props.t('shoppingcart:This discountcode is not valid')}</Alert>}
                    {props.Messages.discountCodeRemoved && <Alert className="align-self-center width-fill"
                                                                  severity="error">{props.t('shoppingcart:The discountcode has been removed')}</Alert>}
                </CardActions>
                <CardActions
                    style={{
                        backgroundColor: `${background_color_7}`,
                    }}
                    className="row"
                >
                    <GeneralButton
                        id="btn"
                        type="button"
                        style={{
                            marginRight: "0px",
                            textAlign: "start",
                        }}
                        className="btn btn-light btn-margin"
                        onClick={(e) => props.handleDiscount(e, props.dataCart, discount_code)}
                        endIcon={<AddShoppingCartIcon color="inherit" />}
                    >
                        {props.t('shoppingcart:Add')}
                    </GeneralButton>
                </CardActions>
            </Card>
        </Grid>
    );
}

export default React.memo(withTranslation('shoppingcart')(DiscountBar));
