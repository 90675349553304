import React, { useState, useEffect } from "react";
import { NavLink, useHistory  } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { options } from "../../config/options";
import { withTranslation } from "react-i18next";
import Image from "react-graceful-image";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {Grid, Divider,TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Logo from "../../components/Logo";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from "@material-ui/icons/AccountCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import {useMutation, useQuery} from "@apollo/client";
import {GET_ALL_PHOTOGRAPHERS, LOGIN_USER} from "../../queries";
import CartComponent from "../../components/Product/CartComponent";

import { useThemeData } from "../../context/ThemeDataContext";
import { useCartData } from "../../context/CartProvider";
import { useAuthData } from "../../context/AuthProvider";
import { useDownloadData } from '../../context/DownloadData';
import GeneralText from "../../components/Fonts";
import GeneralButton from "../../components/Buttons";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import {getRoles, loginArrayFromStorage} from "../../services/auth.service";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    marginLeft: '20px',
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  listItem: {
    width: 'max-content'
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function NavBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const { photographerType } = useThemeData()
  const ICONS = process.env.REACT_APP_ICONS;
  const APP_URL = process.env.REACT_APP_SITE_URL;
  const [lang, setlang] = useState("nl");
  const [Flag, setFlag] = useState("nl");
  const {
    primary_color_1,
    primary_color_6,
    background_color_1,
    background_color_2,
    border_radius,
    font_family_link,
    nav_menu_item_border_radius,
    nav_background_menu_item,
    nav_background_menu_item_hover,
    nav_hover_text_color_menu_item,
    nav_cart_icon_color,
    logo,
  } = useThemeData().themeData.site.theme;
  const { site_id, menus } = useThemeData().themeData.site;
  const { create_account, navigation } = useThemeData().modules;
  const { auth } = useAuthData();
  const { cartData } = useCartData().cart;
  const { ShowCart, setShowCartClass, showCartClass } =
    useCartData().cart.cartDisplay;
  const {DownloadData} = useDownloadData();
  const [fetchedProducts, setFetchedProducts] = useState(false);
  const [amountProducts, setamountProducts] = useState(0);

  let login_name_txt = '';
  let login_names_txt = '';
  if(site_id==='178'){
    login_name_txt = props.t("navbar:Fotocode");
    login_names_txt = props.t("home:PhotoCodes");
  }else{
    login_name_txt = props.t("navbar:Your Login Name");
    login_names_txt = props.t("home:Logins");
  }


  const fotograaf_id = localStorage.hasOwnProperty("photographer_id")
    ? localStorage.getItem("photographer_id") !== "null"
      ? localStorage.getItem("photographer_id")
      : null
    : null;

  const school_logo = localStorage.hasOwnProperty("school_logo")
    ? localStorage.getItem("school_logo") !== "null"
      ? localStorage.getItem("school_logo")
      : null
    : null;

  const login =
    localStorage.hasOwnProperty("login_name") === true
      ? localStorage.getItem("login_name")
      : null;
  const firstPartLogin = login !== null ? login.replace('["', "") : "";
  const MiddelPartLogin = firstPartLogin.replace('","', ", ");
  const login_name = MiddelPartLogin.replace('"]', "");
  const login_email =
    localStorage.hasOwnProperty("login_email") === true
      ? localStorage.getItem("login_email")
      : null;

  const useLinkStyles = makeStyles(() => ({
    navlink: {
      fontFamily: `${font_family_link}`,
      alignSelf: 'center',
      paddingTop: '0px',
      paddingBottom: '0px',
      color: `${primary_color_1} !important`,
      "&:hover": {
        color: `${primary_color_6} !important`,
      },

    },
    navlinka:{
      borderRadius: `${nav_menu_item_border_radius}`,
      backgroundColor: `${nav_background_menu_item}`,
      marginRight: '15px',
      textDecoration: 'none',
      "&:hover": {
        backgroundColor: `${nav_background_menu_item_hover} !important`,
        // nav_hover_text_color_menu_item
      }
    },
    navLinkText:{
      "&:hover": {
        color: `${nav_hover_text_color_menu_item} !important`
      }
    },
    navlinkDropDown: {
        fontFamily: `${font_family_link}`,
        color: `${primary_color_6} !important`,
        "&:hover": {
          color: `${nav_background_menu_item_hover} !important`,
        },
      },

    humburgerBg:{
      borderRadius: `${nav_menu_item_border_radius}`,
      backgroundColor: `${nav_background_menu_item}`,
      marginRight: '15px',
      textDecoration: 'none',
      "&:hover": {
        backgroundColor: `${nav_background_menu_item_hover} !important`,
        // nav_hover_text_color_menu_item
      }
    },
  }));
  const classLink = useLinkStyles();

  const changeLang = (event) => {
    const { i18n } = props;
    const { value } = event.target;

    setlang(lang);
    i18n.changeLanguage(value);
    if (value === "nl") {
      setFlag("nl");
    } else {
      setFlag("en");
    }
  };

  const loggingout = () => {
    localStorage.clear();
  };

  const {
    loading: loadingPhotographer,
    error: errorPhotographer,
    data: dataPhotographer,
  } = useQuery(GET_ALL_PHOTOGRAPHERS, {
    variables: {
      site_id: site_id,
      fotograaf_id: fotograaf_id,
    },
    skip: fotograaf_id === null || site_id === "" || school_logo !== null,
  });

  useEffect(() => {
    if (cartData !== undefined) {
      try {
        /* eslint-disable no-unused-vars */
        const amount_products = fetch(cartData.shoppingcart.amount_products);
        if (
          cartData.shoppingcart.amount_products !== null ||
          cartData.shoppingcart.amount_products !== 0
        ) {
          if (
            amountProducts !== 0 &&
            amountProducts < cartData.shoppingcart.amount_products
          ) {
            setShowCartClass("Cart-opened");
          }
          setamountProducts(cartData.shoppingcart.amount_products);
          setFetchedProducts(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [cartData, amountProducts, setShowCartClass]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    if (auth === true) {
      setAnchorEl(event.currentTarget);
    }else{
      let path = `/`;
      history.push(path);
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const [login_username, setLoginCode] = useState('');
  const [login_password, setLoginPassoword] = useState('');
  const [clients] = useState({
    client_id: 2,
    client_secret: "B4qLlm3bRViBRU6D0DNyxn0V9XtXzeLJV7iUHErt",
  });
  const [loginUser, { loading: loadingCreateUser}] = useMutation(LOGIN_USER, {
    onCompleted: (e) => {
      setLocation();
      console.log(e);
    },
    onError: (error) => {
      console.log(error);
    },
  });


  function setLocation() {
      window.location = "/";
  }

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginArrayFromStorage(login_username, 'login_name');

    loginArrayFromStorage(login_password, 'login_password');


    const queryInput = {
      login_name: login_username,
      login_password: login_password,
      client_id: clients.client_id,
      client_secret: clients.client_secret,
      site_id: site_id,
      album_id: ''
    };

    loginUser({
      variables:
        queryInput

    })
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = photographerType !== 'equestrianphotoservice'  &&
    auth === true ? (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        elevation={0}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
        style={{
          marginLeft:'0px',
          marginTop: '6px'
        }}
      >
        <MenuItem
          onClick={handleMenuClose}
          className={`${classLink.navlinkDropDown} nav-link padding-left-1rimp no-padding font-size-small`}
          style={{
            color: `${primary_color_1}`,
            borderRadius: '0px',
            marginRight: '0px'
          }}
        >

          {login_email !== null ? `${props.t("home:Login")}:${" "} ${login_email}` : `${login_name.search(",") === '-1' ? `${props.t("home:Login")}: ${" "} ${login_name}` :login_names_txt}:${" "} ${login_name.replaceAll('"', "")}`}
        </MenuItem>
        {create_account === "true" && login_email === null && getRoles() !== 'school' && (
          <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlink} nav-link nav-link-layout padding-left-1rimp`}
            style={{
              color: `${primary_color_1}`,
              borderRadius: '0px'
            }}
          >
            <NavLink exact to="/register">
              {props.t("navbar:Create Account")}
            </NavLink>
          </MenuItem>
        )}
          {DownloadData && DownloadData.length > 0 &&
          <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlink} nav-link nav-link-layout padding-left-1rimp`}
            style={{
              color: `${primary_color_1}`,
              borderRadius: '0px'
            }}
          >
            <NavLink exact to="/my-downloads">
              {props.t("navbar:Downloads")}
            </NavLink>
          </MenuItem>
          }

        {create_account === "true" && login_email !== null && (
          <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlink} nav-link nav-link-layout padding-left-1rimp`}
            style={{
              color: `${primary_color_1}`,
              borderRadius: '0px'
            }}
          >
            <NavLink exact to="/register-ticket">
              {props.t("navbar:Match Login Ticket")}
            </NavLink>
          </MenuItem>
        )}
        {login_email === null && getRoles() !== 'school' && (
      <MenuItem>
        <form onSubmit={handleSubmit}>
          <div>
          <TextField
              id="standard-full-width"
              placeholder={login_name_txt}
              fullWidth
              value={login_username}
              onInput={ e=>setLoginCode(
                  e.target.value
              )}
              onKeyDown={handleKeyDown}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
          />
          </div>
        <div>
          <TextField
              id="standard-full-width"
              placeholder="Wachtwoord"
              fullWidth
              value={login_password}
              onInput={ e=>setLoginPassoword(e.target.value)}
              onKeyDown={handleKeyDown}
              // ref={login_nameRef}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
          />
        </div>
        <div>
          <GeneralButton
              type="submit"
              name="submit"
              className="submit_login col-12 col-md-6 col-lg-6"
              endIcon={<HowToRegIcon color="inherit" />}
          >
            {props.t("navbar:Login")}
          </GeneralButton>
        </div>
        </form>
        </MenuItem>
        )}
        <MenuItem>
          <IconButton
            href="/login"
            className={`nav-link nav-link-layout`}
            style={{fontFamily: font_family_link, padding: '0'}}
            onClick={loggingout}
          >
              <ExitToAppIcon style={{fontFamily: font_family_link, padding: '0'}}/>
            {props.t("navbar:Logout")}
          </IconButton>
        </MenuItem>
      </Menu>
    ) : (
     <></>
    );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = photographerType !== 'equestrianphotoservice' && auth === true ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        classes={{ paper: `${classLink.humburgerBg}` }}
      >
            {site_id !== null || site_id !== ""
              ? menus.length >= 1 &&
                menus
                  .filter(
                    (f) =>
                      f.naam === "Hoofdmenu" &&
                      f.hoofd_pagina_id === "0" &&
                      f.url !== "login" &&
                        f.url !== "succes"
                  )[0].pagechildren.map((menuItems, index) => (
                    <MenuItem
                        onClick={handleMenuClose}
                        key={index}
                        className="nav-link padding-left-1rimp no-padding font-size-small"
                    >
                      <NavLink
                        exact
                        to={`/${menuItems.url}`}
                        className={`${classLink.navlinkDropDown}`}
                      >
                        {menuItems.naam}
                      </NavLink>
                    </MenuItem>
                  ))
              : null}
        <Divider variant="middle" component="li" style={{
            backgroundColor: `${background_color_2}`,
        }}/>
        <MenuItem
          onClick={handleMenuClose}
          className={`${classLink.navlinkDropDown} nav-link padding-left-1rimp no-padding font-size-small`}
        >
          {login_email !== null ? `${props.t("home:Login")}:${" "} ${login_email}` : `${login_name.search(",") === '-1' ? `${props.t("home:Login")}: ${" "} ${login_name}` : props.t("home:Logins")}:${" "} ${login_name.replaceAll('"', "")}`}
        </MenuItem>
        <MenuItem>
          <form onSubmit={handleSubmit}>
            <div>
              <TextField
                  id="standard-full-width"
                  placeholder="Gebruikersnaam"
                  fullWidth
                  value={login_username}
                  onKeyDown={handleKeyDown}
                  onInput={ e=>setLoginCode(e.target.value)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <div>
              <TextField
                  id="standard-full-width"
                  placeholder="Wachtwoord"
                  fullWidth
                  value={login_password}
                  onKeyDown={handleKeyDown}
                  onInput={ e=>setLoginPassoword(e.target.value)}
                  // ref={login_nameRef}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
            </div>
            <div>
              <GeneralButton
                  type="submit"
                  name="submit"
                  className="submit_login col-12 col-md-6 col-lg-6"
                  endIcon={<HowToRegIcon color="inherit" />}
              >
                {props.t("login:Login")}
              </GeneralButton>
            </div>
          </form>
        </MenuItem>
        <Divider variant="middle" component="li" style={{
            backgroundColor: `${background_color_2}`,
        }}/>
        {create_account === "true" && login_email === null && (
          <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlinkDropDown} nav-link padding-left-1rimp no-padding font-size-small`}
          >
            <NavLink exact to="/register">
              {props.t("navbar:Create Account")}
            </NavLink>
          </MenuItem>
        )}
        {DownloadData && DownloadData.length > 0 &&
        <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlink} nav-link nav-link-layout padding-left-1rimp`}
          >
            <NavLink exact to="/my-downloads">
              {props.t("navbar:Downloads")}
            </NavLink>
        </MenuItem>
        }
        {create_account === "true" && login_email !== null && (
          <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlinkDropDown} nav-link padding-left-1rimp no-padding font-size-small`}
          >
            <NavLink exact to="/register-ticket">
              {props.t("navbar:Match Login Ticket")}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem>
          <IconButton
            href="/login"
            className={`${classLink.navlinkDropDown}`}
            style={{ textTransform: 'none', fontSize: '1rem', padding: '0rem', textDecoration: '' }}
            onClick={loggingout}
          >
              <ExitToAppIcon/>
            {props.t("navbar:Logout")}
          </IconButton>
        </MenuItem>
      </Menu>
    ) : (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        elevation={0}
        id={mobileMenuId}
        keepMounted
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        classes={{ paper: `${classLink.humburgerBg}` }}

      >
        {site_id !== null || site_id !== ""
            ? menus.length >= 1 &&
            menus
                .filter(
                    (f) =>
                        f.naam === "Hoofdmenu" &&
                        f.hoofd_pagina_id === "0" &&
                        f.url !== "login" &&
                        f.url !== "succes"
                )[0]
                .pagechildren.map((menuItems, index) => {
              if(menuItems.url !== "/" || menuItems.url !== "succes"){
                    return(
                        <NavLink
                            key={index}
                            exact
                            to={`/${menuItems.url}`}
                            className={`${classLink.navlink}, ${classLink.navlinka}`}
                        >
                          <ListItem
                              button
                              className={`${classLink.navlink} ${classes.listItem} ${classLink.navLinkText}`}
                              style={{ lineHeight: "50px" }}
                          >
                            {menuItems.naam}
                          </ListItem>
                        </NavLink>
                    );
                  }else{
                    return(
                        <NavLink
                            key={index}
                            exact
                            to={`/`}
                            className={`${classLink.navlink}, ${classLink.navlinka}`}
                        >
                          <ListItem
                              button
                              className={`${classLink.navlink} ${classes.listItem} ${classLink.navLinkText}`}
                              style={{ lineHeight: "50px" }}
                          >
                            {menuItems.naam}
                          </ListItem>
                        </NavLink>
                    );
                  }
                }
            )
            : null}
      </Menu>
    );

  if (loadingPhotographer) return <p>{props.t("Loading...")}</p>;
  if (errorPhotographer)
    return <p>{props.t("Looks like we have a problem...")}</p>;
  if (!menus) return null;

  if (navigation !== "true" && navigation !== true) return null;

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        style={{
          fontFamily: `${font_family_link}`,
          backgroundColor: `${background_color_1}`,
          color: `${primary_color_1}`,
        }}
      >
        <Toolbar>
          <div id="logo_desktop" className={classes.sectionDesktop} style={{
            marginTop: '5px',
            marginBottom: '5px',
          }}>
            <Logo {...{ APP_URL, site_id, school_logo, dataPhotographer, fotograaf_id, logo }} />
          </div>
          <div id="menu_desktop" className={classes.sectionDesktop}>
            <List
              style={{ display: "flex" }}
              aria-label="main menu"
              className={`${classLink.navlink}`}
            >
              {site_id !== null || site_id !== ""
                  ? menus.length >= 1 &&
                  menus
                      .filter(
                          (f) =>
                              f.naam === "Hoofdmenu" &&
                              f.hoofd_pagina_id === "0" &&
                              f.url !== "login" &&
                              f.url !== "succes"
                      )[0]
                      .pagechildren.map((menuItems, index) => {

                        if (getRoles() !== 'school') {

                          if (menuItems.url !== "/" && menuItems.url !== "succes") {
                            return (
                                <NavLink
                                    key={index}
                                    exact
                                    to={`/${menuItems.url}`}
                                    className={`${classLink.navlink}, ${classLink.navlinka}`}
                                >
                                  <ListItem
                                      button
                                      className={`${classLink.navlink} ${classes.listItem} ${classLink.navLinkText}`}
                                      style={{lineHeight: "50px"}}
                                  >
                                    {menuItems.naam}
                                  </ListItem>
                                </NavLink>
                            );
                          } else {
                            return (
                                <NavLink
                                    key={index}
                                    exact
                                    to={`/`}
                                    className={`${classLink.navlink}, ${classLink.navlinka}`}
                                >
                                  <ListItem
                                      button
                                      className={`${classLink.navlink} ${classes.listItem} ${classLink.navLinkText}`}
                                      style={{lineHeight: "50px"}}
                                  >
                                    {menuItems.naam}
                                  </ListItem>
                                </NavLink>
                            );
                          }
                        }else{
                          return null;
                        }
                      }
                  )
                  : null}

              {getRoles()==='school' ?
              <NavLink
                  key={1}
                  exact
                  to={'/classes/'+login_name}
                  className={`${classLink.navlink}, ${classLink.navlinka}`}
              >
                <ListItem
                    button
                    className={`${classLink.navlink} ${classes.listItem} ${classLink.navLinkText}`}
                    style={{ lineHeight: "50px" }}
                >
                  Vul namen bij klassen in
                </ListItem>
              </NavLink>
              : null}
              {photographerType !== 'equestrianphotoservice' ?

              <ListItem button className={`${classLink.navlink}`} onClick={handleProfileMenuOpen} style={{ paddingRight: "0px", marginLeft: '0px', paddingLeft: '0px' }}>
                {/*line-height: 50px;*/}
                <Button
                  disableRipple
                  disableFocusRipple
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  className={`${classLink.navlink} ${classLink.navlinka}  nav-link`}
                  style={{ marginRight: '0px', lineHeight: "50px", fontSize: "1rem", width: "max-content", textTransform: 'unset', fontFamily: `${font_family_link}`}}
                  startIcon={<AccountCircle />}
                >
                  {site_id==='178' ?
                      <>
                  {props.t("navbar:Foto Toevoegen")}
                      </>
                      :
                    <>
                      {props.t("navbar:My Login")}
                    </>
                  }
              </Button>
              </ListItem>: null}
              {photographerType !== 'equestrianphotoservice' ?
              <ListItem button className={`${classLink.navlink}`} style={{marginLeft: '15px',}}>
                <IconButton disableRipple disableFocusRipple>
                  {Flag === "nl" ? (
                    <Image
                      alt="dutch_flag"
                      src={`${ICONS}iconfinder_NL_167800.png`}
                    />
                  ) : (
                    <Image
                      alt="english_flag"
                      src={`${ICONS}iconfinder_GB_167794.png`}
                    />
                  )}
                  <Select
                    native
                    value={Flag}
                    onChange={changeLang}
                    className="App-Select NavDropdown"
                    inputProps={{ "aria-label": "lang" }}
                    style={{ fontFamily: `${font_family_link}`, color: `${nav_cart_icon_color}!important` }}
                  >
                    {options.map((o) => (
                      <option
                        className="font-transform-uppercase"
                        style={{ color: `${nav_cart_icon_color}!important` }}
                        key={o.value}
                        value={o.value}
                      >
                        {o.value}
                      </option>
                    ))}
                  </Select>
                </IconButton>
              </ListItem>: null}
            </List>
          </div>
          <div id="menu_mobile" className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              style={{ color: `${nav_cart_icon_color}` }}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.grow} />
          <div id="logo_mobile" className={classes.sectionMobile}>
            <Logo {...{ APP_URL, site_id, school_logo, dataPhotographer, fotograaf_id, logo }} />
          </div>
          {(auth === true && getRoles()!=='school') && (
            <div
              id="cart_mobile"
              className={classes.sectionMobile}
              onClick={ShowCart}
            >
              {fetchedProducts &&
              cartData.shoppingcart.amount_products !== null ? (
                <IconButton
                  aria-label="show-cart-items"
                  onClick={ShowCart}
                  variant="contained"
                  type="button"
                  name="cart"
                  style={{
                    fontFamily: `${font_family_link}`,
                    alignSelf: 'center',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    minWidth: "1rem",
                    width: "2.1rem",
                    height: "2rem",
                    borderRadius: "1rem",
                    color: `${nav_cart_icon_color} !important`,
                  }}
                >
                  <Badge
                    badgeContent={cartData.shoppingcart.amount_products}
                    color="secondary"
                    overlap="rectangular"
                    style={{fontFamily: `${font_family_link}`}}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  aria-label="show cart items"
                  variant="contained"
                  type="button"
                  name="cart"
                  style={{
                    color: `${nav_cart_icon_color}!important`,
                    fontFamily: `${font_family_link}`,
                    alignSelf: 'center',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    height: "2rem",
                    borderRadius: border_radius
                  }}
                >
                  <Badge
                    style={{fontFamily: `${font_family_link}`, color: `${nav_cart_icon_color}!important`}}
                    color="secondary"
                    overlap="rectangular"
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              )}
            </div>
          )}
          {(auth === true && getRoles()!=='school') && (
            <div
              id="cart_desktop"
              className={classes.sectionDesktop}
              onClick={ShowCart}
            >
              {fetchedProducts &&
              cartData.shoppingcart.amount_products !== null ? (
                <IconButton
                  aria-label="show-cart-items"
                  onClick={ShowCart}
                  variant="contained"
                  type="button"
                  name="cart"
                  style={{
                    fontFamily: `${font_family_link}`,
                    alignSelf: 'center',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    minWidth: "1rem",
                    width: "2.1rem",
                    height: "2rem",
                    borderRadius: "1rem",
                    color: `${nav_cart_icon_color} !important`,
                  }}
                >
                  <Badge
                    badgeContent={cartData.shoppingcart.amount_products}
                    color="secondary"
                    overlap="rectangular"
                    style={{fontFamily: `${font_family_link}`, color: `${nav_cart_icon_color}`}}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  aria-label="show cart items"
                  variant="contained"
                  type="button"
                  name="cart"
                  style={{
                    color: `${nav_cart_icon_color} !important`,
                    fontFamily: `${font_family_link}`,
                    alignSelf: 'center',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    height: "2rem",
                    borderRadius: border_radius
                  }}
                >
                  <Badge
                    style={{fontFamily: `${font_family_link}`}}
                    color="secondary"
                    overlap="rectangular"
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              )}
            </div>
          )}
          {showCartClass === "Cart-opened" ? (
            <div
              className={`${showCartClass} minicart`}
              style={{ boxShadow: `1px 1px 10px black` }}
              key={1}
            >
              <Grid container direction="row" className="Cart-header" style={{justifyContent: 'space-around'}}>
                <Grid item lg={10}>
                  <GeneralText
                      style={{color: `black`}}
                      variant="h5"
                      gutterBottom={true}
                  >
                      {props.t("navbar:Cart")}
                  </GeneralText>
                </Grid>
                <Grid item lg={2} className="align-self-center">
                  <IconButton
                    id="btn"
                    onClick={ShowCart}
                    type="button"
                    name="close"
                    style={{
                      width: "2.1rem",
                      height: "2rem",
                      borderRadius: "1rem",
                      color: `black`,
                    }}
                    aria-label="close mini shopping cart"
                  >
                    <CloseIcon color="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
              <CartComponent {...props} navbar={true} cart={true} />
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
export default React.memo(
  withTranslation(["message", "home", "navbar"])(NavBar)
);
