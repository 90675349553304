import React, { useEffect, useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from "@material-ui/core";
import { useThemeData } from '../../context/ThemeDataContext';
import GeneralButton from "../Buttons";

function EffectComponent({
                             user_id,
                             product_type,
                             product_id,
                             type,
                             t,
                             closepopup_effect,
                             effectData
                         }) {
    const { site_id, theme: { background_color_2 } } = useThemeData().themeData.site;
    const APP_URL = process.env.REACT_APP_SITE_URL;
    const [effectsData, setEffectsData] = useState([]);

    const loadImage = async src => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
            img.onerror = reject;
            img.src = src;
        });
    };

    const determineImageOrientation = sizes => sizes.width > sizes.height ? 'horizontal' : 'vertical';

    const filterEffectByOrientation = useCallback(async effect => {
        try {
            const sizes = await loadImage(`${APP_URL}${site_id}/userfiles/effecten/thumb/${effect.png_src}`);
            const orientation = determineImageOrientation(sizes);
            if ((['groepsfoto', 'groepsfoto download'].includes(product_type) && orientation === 'horizontal') ||
                (orientation === 'vertical')) {
                return effect;
            }
        } catch (error) {
            console.error('Error loading image:', error);
        }
        return null;
    }, [product_type, APP_URL, site_id]);

    useEffect(() => {
        const loadEffects = async () => {
            const loadedEffects = await Promise.all(effectData.map(filterEffectByOrientation));
            setEffectsData(loadedEffects.filter(effect => effect !== null));
        };

        loadEffects();
    }, [effectData, filterEffectByOrientation]);

    return (
        <Grid container className="justify-content-center">
            {effectsData.filter(e => e.voor_achter === type).map(effect => (
                <Grid item xs={12} sm={6} md={3} className="text-center" key={effect.id}>
                    <GeneralButton
                        className="btn btn-light no-padding"
                        style={{ backgroundColor: '#ffffff', borderColor: '#ffffff', marginBottom: '1rem' }}
                        onClick={() => closepopup_effect(effect.png_src, effect.id, effect.voor_achter)}
                    >
                        <img
                            src={`${APP_URL}${site_id}/userfiles/effecten/thumb/${effect.png_src}`}
                            className="image small-image-size effect_image"
                            alt={`Effect ${effect.id}`} // Improved alt text
                            style={{ minWidth: '1rem', border: `2px solid ${background_color_2}` }}
                        />
                    </GeneralButton>
                </Grid>
            ))}
        </Grid>
    );
}

export default React.memo(withTranslation('message')(EffectComponent));