import * as yup from 'yup'

const postcode_validation_message = "Postcode validation";

// const postcode_regex = new RegExp("^[1-9][0-9]{3}[A-Za-z]{2}$");
const empty_field = "EmptyField"

const ShowKlantPostcodeIfDownloads = {
    is: true,
    then: yup.string().nullable().notRequired(),
    otherwise: yup.string().required(empty_field).min(4, postcode_validation_message).max(7, postcode_validation_message)
}

const ShowKlantHuisnummerIfDownloads = {
    is: true,
    then: yup.string().nullable().notRequired(),
    otherwise: yup.string().required(empty_field)
}

const ShowKlantStreetIfDownloads = {
    is: true,
    then: yup.string().nullable().notRequired(),
    otherwise: yup.string().required(empty_field)
}

const ShowKlantPlaatsIfDownloads = {
    is: true,
    then: yup.string().nullable().notRequired(),
    otherwise: yup.string().required(empty_field)
}

export const AddressSchema = yup.object().shape({
    klant_voornaam: yup.string().required(empty_field),
    customer_preposition: yup.string(),
    klant_achternaam: yup.string().required(empty_field),
    klant_email: yup.string().required(empty_field).email("ErrorEmail"),
    klant_telnr: yup.string(),

    downloads: yup.boolean(), //dit veld word niet weergegeven
    klant_postcode: yup.string().when('downloads', ShowKlantPostcodeIfDownloads),
    klant_huisnummer: yup.string().when('downloads', ShowKlantHuisnummerIfDownloads),
    klant_straat: yup.string().when('downloads', ShowKlantStreetIfDownloads),
    klant_plaats: yup.string().when('downloads', ShowKlantPlaatsIfDownloads),


    //hidden checkbox
    oudste_kind: yup.boolean(),
    //if hidden checkbox oudste_kind is checkt requere fields
    klant_oudste_kind: yup.string().when('oudste_kind', {
        is: true,
        then: yup.string().required(empty_field),
        otherwise: yup.string().optional()
    }),
    klant_oudste_kind_klas: yup.string().when('oudste_kind', {
        is: true,
        then: yup.string().required().notOneOf(['select'], 'U moet een klas selecteren.'),
        otherwise: yup.string().optional()
    }),
    shipping_urgent: yup.string(),
    shipping_method: yup.string(),
    opmerking: yup.string(),
    // acceptTerms: yup.bool()
    //   .required("Accepting terms is required") // This ensures the field is required
    //   .oneOf([true], "You must accept the terms and conditions")
    // acceptTerms: yup
    //     .boolean()
    //     .oneOf([true], "Required terms of use")
    //     .required("Required terms of use"),

});
