// Importing necessary libraries and components
import React from "react";
import { withTranslation } from 'react-i18next';
import { useQuery } from "@apollo/client";
import { useThemeData } from '../context/ThemeDataContext';
import { GET_CLASSES } from "../queries";
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import { useAuthData } from "../context/AuthProvider";

function Classes(props) {
    // Extracting site_id from ThemeDataContext
    const { site_id } = useThemeData().themeData.site;

    // Extracting folder_id from URL parameters
    const folder_id = useParams().folder_id;

    // Extracting authentication data from AuthProvider
    const { auth } = useAuthData();

    // Portrait names array for matching folder names
    const portraitNames = [
        "portretfotos",
        "portretfoto",
        "portret",
        "portretten",
        // ... add other variations as needed
    ];

    // Query to fetch class data with Apollo Client
    const {
        loading: loadingClasses,
        error: errorClasses,
        data: dataClasses,
    } = useQuery(GET_CLASSES, {
        variables: { site_id, folder_id },
        skip: folder_id === undefined,
        notifyOnNetworkStatusChange: true,
    });

    // Function to navigate to a specific class card
    const goTo = (data) => {
        let location = {
            pathname: `/classcards/${folder_id}/${data.folder_id}`,
            state: { data }
        }
        props.history.push(location)
    }

    // Function to check if the folder name matches portrait names
    function isPortraitFolder(folderName) {
        const processedName = folderName.toLowerCase().replace(/[&\\#,+()$~%.'":*?<>{}]/g, '');
        return portraitNames.includes(processedName);
    }

    // Handling loading and error states
    if (loadingClasses) return <p>{props.t('message:Loading...')}</p>
    if (errorClasses) return <p>{props.t('Looks like we have a problem...')}</p>

    // Rendering class cards if authenticated
    return auth ? (
        <>
            {dataClasses.getAlbums.folderchildren.map((classes) => (
                classes.folderchildren.map((portretfotos) => (
                    isPortraitFolder(portretfotos.folder_naam) ? (
                        <div>
                            <Button
                                style={{ fontSize: "1.5rem" }}
                                className="buttonWidth"
                                onClick={() => goTo(portretfotos)}
                            >
                                {classes.folder_naam}
                            </Button>
                        </div>
                    ) : null
                ))
            ))}
        </>
    ) : null;
}

// Exporting the component with memoization and translations
export default React.memo(withTranslation(['classes'])(Classes));