export const formContent = {
    inputs: [
      {
        id:"email_sender",
        name:"email_sender",
        type:"text",
        autocomplete:"email",
        label: 'Your Email',
      },
      {
        id:"firstname_sender",
        name:"firstname_sender",
        type:"text",
        autocomplete:"given-name",
        label: 'Your First name',
      },
      {
        id:"lastname_sender",
        name:"lastname_sender",
        type:"text",
        autocomplete:"family-name",
        label: 'Your Last name',
      },
      {
        id:"subject",
        name:"subject",
        type:"text",
        autocomplete: undefined,
        label: 'Subject',
      },
      {
        id:"message",
        name:"message",
        type:"text",
        autocomplete: undefined,
        label: 'Message',
        multiline: true,
        rows:4
      }
    ]
  }
