import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'jwt';

export function isAuthenticated() {
  const jwt = localStorage.getItem(TOKEN_KEY);
  if (!jwt) {
    return false;
  }

  const jwtDecoded = jwtDecode(jwt);
  const currentTime = Date.now() / 1000;
  if (jwtDecoded.exp < currentTime) {
    return false;
  }

  if (!localStorage.getItem('session_id')) {
    // const generateRandomNumber = (min, max) =>
    //     Math.floor(Math.random() * (max - min + 1)) + min;
    localStorage.setItem('session_id', Date.now());
  }

  return true;
}

export function getRoles() {
  const jwt = localStorage.getItem(TOKEN_KEY);
  if (!jwt) {
    return null;
  }

  const jwtDecoded = jwtDecode(jwt);
  const { roles } = jwtDecoded.user;

  return roles || null;
}

export function saveTokens(tokens) {
  localStorage.setItem(TOKEN_KEY, tokens);
}

export function saveLoginData(loginName, loginPassword) {
  localStorage.setItem('login_name', JSON.stringify(loginName));
  localStorage.setItem('login_password', JSON.stringify(loginPassword));
}

export function getTokens() {
  return localStorage.getItem(TOKEN_KEY);
}

export function deleteTokens() {
  localStorage.clear();
}

export function loginHashArrayFromStorage(loginHash) {
  // Get the current array of login hashes or initialize as empty array
  let loginHashes;
  try {
    loginHashes = JSON.parse(localStorage.getItem('login_hash')) || [];
  } catch (error) {
    console.error("Failed to parse login hashes from local storage:", error);
    loginHashes = [];
  }

  // Convert loginHash to string (if necessary) and add it if not already present
  const loginHashString = loginHash.toString();
  if (!loginHashes.includes(loginHashString)) {
    loginHashes.push(loginHashString);
    localStorage.setItem('login_hash', JSON.stringify(loginHashes));
  }

  // Return the comma-separated string of login usernames
  return loginHashes.join(',');
}

export function loginArrayFromStorage(data, type) {
  let login = '';

  const logins = JSON.parse(localStorage.getItem(type)) || [];
  logins.push(data.toString());
  localStorage.setItem(type, JSON.stringify(logins));
  login = logins.join(',');

  return login;
}
