import React from 'react';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { GET_DOWNLOAD } from '../queries';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Grid } from '@material-ui/core';
import { useThemeData } from '../context/ThemeDataContext';
import GeneralButton from '../components/Buttons';
import GeneralText from '../components/Fonts';

function Download(props) {
  const {
    site_id,
    theme: { font_family, primary_color_2 },
  } = useThemeData().themeData.site;

  const { loading: loadingDownload, error: errorDownload, data: dataDownload } = useQuery(GET_DOWNLOAD, {
    variables: {
      site_id: site_id,
      hash: props.match.params.hash,
    },
    fetchPolicy: 'no-cache',
    skip: !props.match.params.hash,
  });

  if (loadingDownload) return <p>{props.t('Loading...')}</p>;
  if (errorDownload) return <p>Helaas is er geen download gevonden.</p>;
  if (!dataDownload) return null;

  const getDownloads = () => {
    const link = document.createElement('a');
    link.href = dataDownload.getDownload.hash;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const setDocumentTitle = () => {
    const parts = window.location.href.split('/');
    document.title = parts[2];
  };

  setDocumentTitle();

  return (
      <Grid
          item
          lg={12}
          md={12}
          style={{
            fontFamily: `${font_family}`,
            color: `${primary_color_2}`,
          }}
      >
        <GeneralText variant="h3" gutterBottom={true}>
          {props.t('shoppage:Thank you')}
        </GeneralText>

        <div className="download-text">
          <p>{props.t('Downloads genereren')}</p>
        </div>

        <GeneralButton
            onClick={() => getDownloads()}
            name="download"
            className="btn btn-secondary mb-2"
            startIcon={<CloudDownloadIcon color="inherit" />}
        >
          {props.t('shoppage:Download')}
        </GeneralButton>
      </Grid>
  );
}

export default React.memo(withTranslation(['message', 'shoppage'])(Download));
