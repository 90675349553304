import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

const ProductProperties = ({ productVariationGroups, handleVariationChangePhoto }) => {
    const [selectedProperties, setSelectedProperties] = useState(
        Array.isArray(productVariationGroups) && productVariationGroups.length > 0 ? {} : {}
    );

    const handleChange = (groupId, variationId) => (event) => {
        const selectedValue = event.target.value;
        const group = productVariationGroups.find(g => String(g.id) === String(groupId));

        setSelectedProperties(prevSelectedProperties => {
            const updatedProperties = { ...prevSelectedProperties };

            if (group.type === 'text') {
                // Logic for text type variations
                const groupProperties = updatedProperties[group.name] || {};
                const variation = group.product_variations.find(variation => variation.id === variationId);
                if (variation) {
                    groupProperties[variation.name] = selectedValue;
                }
                updatedProperties[group.name] = groupProperties;
            } else {
                // Directly store the selected name for the group
                updatedProperties[group.name] = selectedValue;
            }

            return updatedProperties;
        });
    };

    useEffect(() => {
        handleVariationChangePhoto(selectedProperties);
    }, [selectedProperties, handleVariationChangePhoto]);

    if (!Array.isArray(productVariationGroups)) {
        return null;
    }

    return (
        <Box>
            {productVariationGroups.map(group => {
                if (!Array.isArray(group.product_variations)) {
                    return null;
                }

                return group.type === 'text' ? (
                    group.product_variations.map(variation => (
                        <FormControl key={variation.id} fullWidth margin="normal">
                            <TextField
                                label={`${group.name} - ${variation.name}`}
                                value={selectedProperties[group.name]?.[variation.name] || ''}
                                onChange={handleChange(group.id, variation.id)}
                                variant="outlined"
                            />
                        </FormControl>
                    ))
                ) : (
                    <FormControl key={group.id} fullWidth margin="normal">
                        <InputLabel>{group.name}</InputLabel>
                        <Select
                            value={selectedProperties[group.name] || ''}
                            onChange={handleChange(group.id, null)}
                            label={group.name}
                        >
                            {group.product_variations.map(variation => (
                                <MenuItem key={variation.id} value={variation.name}>
                                    {variation.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            })}
        </Box>
    );
};

export default ProductProperties;