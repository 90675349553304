import React from "react";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useThemeData } from "../../context/ThemeDataContext";

import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import LockIcon from "@material-ui/icons/Lock";
import GeneralButton from "../../components/Buttons";

function AlbumCard(props) {
  const { site_id } = useThemeData().themeData.site;
  const { primary_color_4, background_color_2 } =
  useThemeData().themeData.site.theme;
  const PHOTO_URL = process.env.REACT_APP_PHOTO_URL;
  const album = props.data;
  const {handleClick} = props;

  console.log(album);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    buttonBox: {
      // margin: "0.5rem",
      backgroundSize: "cover !important",
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: "center center !important",
      // height: "24vh",
      position: "relative",
      transition: ".7s ease-in",
      "&:hover": {
        transform: 'scale(0.99)',
        opacity: "0.8",
        button: {
          opacity: "1",
        },
      },
    },
    button: {
      opacity: "0.5",
      position: "absolute",
      transition: ".5s ease-in",
      width: "-webkit-fill-available",
      height: "-webkit-fill-available",
      bottom: 0,
      left: 0,
      backgroundColor: `${background_color_2}`,
      "&:hover": {
        opacity: "1",
      },
    },
    buttonText: {
      color: primary_color_4,
      fontSize: "17px",
      position: "absolute",
      display: "contents",
      transform: "translate(-50%, -50%)",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
  }));

  const classes = useStyles();


  console.log(album.authorization);

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <>
            {album.authorization === "true" || album.authorization === null  ? (
              <Tooltip title="open album" placement="bottom">
                <IconButton
                  aria-label="open album"
                  onClick={() => handleClick(album, true)}
                >
                  <FolderOpenIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="login and open album" placement="bottom">
                <IconButton
                  aria-label="login and open album"
                  onClick={() => handleClick(album, false)}
                >
                  <LockIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
        title={`${album.folder_naam}`}
        subheader={
          album.uitlever_datum === null
            ? "geen uitlever datum"
            : album.uitlever_datum
        }
      />
      <Grid className={classes.buttonBox}>
        <CardMedia
          className={classes.media}
          image={`${PHOTO_URL}${site_id}/${album.first_photo_folder_id}/0/0/0/0/${album.first_photo_id}/${album.first_file_name}`}
          title={album.first_file_name}
        />
        {album.authorization === "true" || album.authorization === null ? (
          <GeneralButton
            className={classes.button}
            onClick={() => handleClick(album, true)}
          >
            <p className={classes.buttonText}>{album.folder_naam}</p>
          </GeneralButton>
        ) : (
          <GeneralButton
            className={classes.button}
            onClick={() => handleClick(album, false)}
          >
            <p className={classes.buttonText}>
              <LockIcon
                style={{
                  height: "1.5rem",
                  color: primary_color_4,
                  paddingTop: "5px",
                }}
              />
              {album.folder_naam}
            </p>
          </GeneralButton>
        )}
      </Grid>
    </Card>
  );
}

export default React.memo(withTranslation("shoppingcart")(AlbumCard));
