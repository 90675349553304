import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { options } from "../../config/options";
import { withTranslation } from "react-i18next";
import Image from "react-graceful-image";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Grid, Divider } from "@material-ui/core";
import Select from "@material-ui/core/Select";

import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { useQuery } from "@apollo/client";
import { GET_ALL_PHOTOGRAPHERS } from "../../queries";
import CartComponent from "../../components/Product/CartComponent";
import GeneralText from "../../components/Fonts";

import Drawer from "@material-ui/core/Drawer";

import { useThemeData } from "../../context/ThemeDataContext";
import { useCartData } from "../../context/CartProvider";
import { useAuthData } from "../../context/AuthProvider";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  listItem: {
    width: "max-content",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function NavBar(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { photographerType } = useThemeData();
  const ICONS = process.env.REACT_APP_ICONS;
  const APP_URL = process.env.REACT_APP_SITE_URL;
  const [lang, setlang] = useState("nl");
  const [Flag, setFlag] = useState("nl");
  const {
    primary_color_1,
    primary_color_6,
    background_color_1,
    background_color_2,
    border_radius,
    font_family_link,
    logo,
  } = useThemeData().themeData.site.theme;
  const { site_id, menus } = useThemeData().themeData.site;
  const { create_account, navigation } = useThemeData().modules;
  const { auth } = useAuthData();
  const { cartData } = useCartData().cart;
  const { ShowCart, setShowCartClass, showCartClass } =
    useCartData().cart.cartDisplay;
  const [fetchedProducts, setFetchedProducts] = useState(false);
  const [amountProducts, setamountProducts] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [mobileOpen, setMobileOpen] = useState(false);

  const fotograaf_id = localStorage.hasOwnProperty("photographer_id")
    ? localStorage.getItem("photographer_id") !== "null"
      ? localStorage.getItem("photographer_id")
      : null
    : null;

  const school_logo = localStorage.hasOwnProperty("school_logo")
    ? localStorage.getItem("school_logo") !== "null"
      ? localStorage.getItem("school_logo")
      : null
    : null;

  const login =
    localStorage.hasOwnProperty("login_name") === true
      ? localStorage.getItem("login_name")
      : null;
  const firstPartLogin = login !== null ? login.replace('["', "") : "";
  const MiddelPartLogin = firstPartLogin.replace('","', ", ");
  const login_name = MiddelPartLogin.replace('"]', "");
  const login_email =
    localStorage.hasOwnProperty("login_email") === true
      ? localStorage.getItem("login_email")
      : null;

  const drawerWidth = 240;
  const useDrawerStyles = makeStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      fontFamily: `${font_family_link}`,
      backgroundColor: `${background_color_1}`,
      color: `${primary_color_1}`,
    },
    appBar: {
      fontFamily: `${font_family_link}`,
      backgroundColor: `${background_color_1}`,
      color: `${primary_color_1}`,
    },
  });
  const classStyle = useDrawerStyles();

  const useLinkStyles = makeStyles(() => ({
    navlink: {
      color: `${primary_color_1} !important`,
      "&:hover": {
        color: `${primary_color_6} !important`,
      },
    },
    navlinkDropDown: {
      color: `${primary_color_6} !important`,
      "&:hover": {
        color: `${primary_color_1} !important`,
      },
    },
  }));
  const classLink = useLinkStyles();

  const changeLang = (event) => {
    const { i18n } = props;
    const { value } = event.target;

    setlang(lang);
    i18n.changeLanguage(value);
    if (value === "nl") {
      setFlag("nl");
    } else {
      setFlag("en");
    }
  };

  const loggingout = () => {
    let session_id = localStorage.getItem("session_id");
    localStorage.clear();
    localStorage.setItem("session_id", session_id);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const {
    loading: loadingPhotographer,
    error: errorPhotographer,
    data: dataPhotographer,
  } = useQuery(GET_ALL_PHOTOGRAPHERS, {
    variables: {
      site_id: site_id,
      fotograaf_id: fotograaf_id,
    },
    skip: fotograaf_id === null || site_id === "" || school_logo !== null,
  });

  useEffect(() => {
    if (cartData !== undefined) {
      try {
        /* eslint-disable no-unused-vars */
        const amount_products = fetch(cartData.shoppingcart.amount_products);
        if (
          cartData.shoppingcart.amount_products !== null ||
          cartData.shoppingcart.amount_products !== 0
        ) {
          if (
            amountProducts !== 0 &&
            amountProducts < cartData.shoppingcart.amount_products
          ) {
            setShowCartClass("Cart-opened");
          }
          setamountProducts(cartData.shoppingcart.amount_products);
          setFetchedProducts(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [cartData, amountProducts, setShowCartClass]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const menuId = "primary-search-account-menu";
  const renderMenu =
    photographerType !== "equestrianphotoservice" && auth === true ? (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        elevation={0}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={handleMenuClose}
          className="nav-link padding-left-1rimp no-padding font-size-small"
          style={{
            color: `${primary_color_1}`,
            backgroundColor: `${background_color_1}`,
          }}
        >
          {props.t("home:Login")}:{" "}
          {login_email !== null ? login_email : login_name}
        </MenuItem>
        {create_account === "true" && (
          <MenuItem
            onClick={handleMenuClose}
            className="nav-link nav-link-layout padding-left-1rimp"
          >
            <NavLink exact to="/register">
              {props.t("navbar:Create Account")}
            </NavLink>
          </MenuItem>
        )}
        {create_account === "true" && (
          <MenuItem
            onClick={handleMenuClose}
            className="nav-link nav-link-layout padding-left-1rimp"
          >
            <NavLink exact to="/register-ticket">
              {props.t("navbar:Match Login Ticket")}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem>
          <IconButton
            href="/login"
            className="nav-link nav-link-layout padding-left-1rimp"
            style={{ fontFamily: `${font_family_link}` }}
            onClick={loggingout}
          >
            <ExitToAppIcon />
            {props.t("navbar:Logout")}
          </IconButton>
        </MenuItem>
      </Menu>
    ) : (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        elevation={0}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <NavLink exact to="/login">
            {props.t("navbar:Login")}
          </NavLink>
        </MenuItem>
      </Menu>
    );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu =
    photographerType !== "equestrianphotoservice" && auth === true ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {site_id !== null || site_id !== ""
          ? menus.length >= 1 &&
            menus
              .filter(
                (f) =>
                  f.naam === "Hoofdmenu" &&
                  f.hoofd_pagina_id === "0" &&
                  f.url !== "login"
              )[0]
              .pagechildren.map((menuItems, index) => (
                <MenuItem
                  onClick={handleMenuClose}
                  key={index}
                  className="nav-link padding-left-1rimp no-padding font-size-small"
                >
                  <NavLink
                    exact
                    to={`/${menuItems.url}`}
                    className={`${classLink.navlinkDropDown}`}
                  >
                    {menuItems.naam}
                  </NavLink>
                </MenuItem>
              ))
          : null}
        <Divider
          variant="middle"
          component="li"
          style={{
            backgroundColor: `${background_color_2}`,
          }}
        />
        <MenuItem
          onClick={handleMenuClose}
          className={`${classLink.navlinkDropDown} nav-link padding-left-1rimp no-padding font-size-small`}
        >
          {props.t("home:Login")}:{" "}
          {login_email !== null ? login_email : login_name}
        </MenuItem>
        <Divider
          variant="middle"
          component="li"
          style={{
            backgroundColor: `${background_color_2}`,
          }}
        />
        {create_account === "true" && (
          <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlinkDropDown} nav-link padding-left-1rimp no-padding font-size-small`}
          >
            <NavLink exact to="/register">
              {props.t("navbar:Create Account")}
            </NavLink>
          </MenuItem>
        )}
        {create_account === "true" && (
          <MenuItem
            onClick={handleMenuClose}
            className={`${classLink.navlinkDropDown} nav-link padding-left-1rimp no-padding font-size-small`}
          >
            <NavLink exact to="/register-ticket">
              {props.t("navbar:Match Login Ticket")}
            </NavLink>
          </MenuItem>
        )}
        <MenuItem>
          <IconButton
            href="/login"
            className={`${classLink.navlinkDropDown}`}
            style={{
              fontFamily: `${font_family_link}`,
              textTransform: "none",
              fontSize: "1rem",
              padding: "0rem",
              textDecoration: "",
            }}
            onClick={loggingout}
          >
            <ExitToAppIcon />
            {props.t("navbar:Logout")}
          </IconButton>
        </MenuItem>
      </Menu>
    ) : (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        elevation={0}
        id={mobileMenuId}
        keepMounted
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <NavLink exact to="/login">
            {props.t("navbar:Login")}
          </NavLink>
        </MenuItem>
      </Menu>
    );

  const drawer = (
    <div>
      <div
        id="logo_desktop"
        style={{
          fontFamily: `${font_family_link}`,
          backgroundColor: `${background_color_1}`,
          color: `${primary_color_1}`,
        }}
      >
        {school_logo !== null ? (
          <NavLink exact to="/">
            <Image
              src={`${APP_URL}${site_id}/userfiles/school_logos/${school_logo}`}
              className="navbar-brand-logo"
              alt="schoolfotograaf logo"
            />
          </NavLink>
        ) : fotograaf_id !== null ? (
          <NavLink exact to="/">
            <Image
              src={`${APP_URL}${site_id}/theme/logos/${dataPhotographer.photographer.afbeelding}`}
              className="navbar-brand-logo"
              alt="fotograaf logo"
            />
          </NavLink>
        ) : (
          <NavLink exact to="/">
            <Image
              src={`${APP_URL}${site_id}/theme/logos/${logo}`}
              className="navbar-brand-logo"
              alt="site logo"
            />
          </NavLink>
        )}
      </div>
      <Divider />
      <div
        id="menu_desktop"
        style={{
          fontFamily: `${font_family_link}`,
          backgroundColor: `${background_color_1}`,
          color: `${primary_color_1}`,
        }}
      >
        <List
          style={{ display: "flex", flexDirection: "column" }}
          aria-label="main menu"
          className={`${classLink.navlink}`}
        >

          <Divider />
          <ListItem button className={`${classLink.navlink}`}>
            <Button
              disableRipple
              disableFocusRipple
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              className={`${classLink.navlink} nav-link`}
              style={{
                padding: "0px",
                fontSize: "1rem",
                width: "max-content",
                textTransform: "unset",
                fontFamily: `${font_family_link}`,
              }}
            >
              {props.t("navbar:My Login")} ▾
            </Button>
          </ListItem>
          {site_id !== null || site_id !== ""
            ? menus.length >= 1 &&
              menus
                .filter(
                  (f) =>
                    f.naam === "Hoofdmenu" &&
                    f.hoofd_pagina_id === "0" &&
                    f.url !== "login"
                )[0]
                .pagechildren.map((menuItems, index) => (
                  <ListItem
                    button
                    className={`${classLink.navlink}`}
                    key={index}
                  >
                    <NavLink
                      exact
                      to={`/${menuItems.url}`}
                      className={`${classLink.navlink} ${classes.listItem}`}
                    >
                      {menuItems.naam}
                    </NavLink>
                  </ListItem>
                ))
            : null}
         <Divider />

          <ListItem button className={`${classLink.navlink}`}>
            <IconButton disableRipple disableFocusRipple style={{padding: '0px', borderRadius: 'none'}}>
              {Flag === "nl" ? (
                <Image
                  alt="dutch_flag"
                  src={`${ICONS}iconfinder_NL_167800.png`}
                />
              ) : (
                <Image
                  alt="english_flag"
                  src={`${ICONS}iconfinder_GB_167794.png`}
                />
              )}

              <Select
                native
                value={Flag}
                onChange={changeLang}
                className="App-Select NavDropdown"
                inputProps={{ "aria-label": "lang" }}
                style={{
                  fontFamily: `${font_family_link}`,
                  color: `${primary_color_1}`,
                  textAlignLast: `center`,
                }}
              >
                {options.map((o) => (
                  <option
                    className="font-transform-uppercase"
                    style={{ color: `${primary_color_6}` }}
                    key={o.value}
                    value={o.value}
                  >
                    {o.value}
                  </option>
                ))}
              </Select>
            </IconButton>
          </ListItem>
          {auth === true && (
            <ListItem
              button
              className={`${classLink.navlink}`}
            >
              <div id="cart_desktop" onClick={ShowCart}>
                {fetchedProducts &&
                cartData.shoppingcart.amount_products !== null ? (
                  <IconButton
                    aria-label="show-cart-items"
                    className={`${classLink.navlink}`}
                    onClick={ShowCart}
                    variant="contained"
                    type="button"
                    name="cart"
                    style={{
                      minWidth: "1rem",
                      width: "2.1rem",
                      height: "2rem",
                      borderRadius: "1rem",
                      color: `${primary_color_1}`,
                    }}
                  >
                    <Badge
                      badgeContent={cartData.shoppingcart.amount_products}
                      color="secondary"
                      style={{ fontFamily: `${font_family_link}` }}
                    >
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="show cart items"
                    className={`${classLink.navlink}`}
                    variant="contained"
                    type="button"
                    name="cart"
                    style={{
                      height: "2rem",
                      borderRadius: border_radius,
                    }}
                  >
                    <Badge
                      style={{ fontFamily: `${font_family_link}` }}
                      color="secondary"
                    >
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                )}
              </div>
            </ListItem>
          )}
        </List>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const cart = (
    <>
      <Grid container direction="row" className="Cart-header">
        <Grid item lg={10}>
          <GeneralText
              style={{color: `black`}}
              variant="h5"
              gutterBottom={true}
            >
              {props.t("navbar:Cart")}
          </GeneralText>
        </Grid>
        <Grid item lg={2} className="align-self-center">
          <IconButton
            id="btn"
            onClick={ShowCart}
            type="button"
            name="close"
            style={{
              width: "2.1rem",
              height: "2rem",
              borderRadius: "1rem",
              color: `black`,
            }}
            aria-label="close mini shopping cart"
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </Grid>
      </Grid>
      <CartComponent {...props} navbar={true} cart={true} />
    </>
  );

  if (loadingPhotographer) return <p>{props.t("Loading...")}</p>;
  if (errorPhotographer)
    return <p>{props.t("Looks like we have a problem...")}</p>;
  if (!menus || photographerType === "equestrianphotoservice") return null;

  if (navigation !== "true" && navigation !== true) return null;
  return (
    <nav aria-label="side-navbar" className={classes.grow}>
      <Hidden smUp implementation="css">
        <AppBar position="fixed" className={classStyle.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classStyle.drawer}
          classes={{
            paper: classStyle.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        {showCartClass === "Cart-opened" ? (
          <div
            className={`${showCartClass} minicart`}
            style={{ boxShadow: `1px 1px 10px black`, zIndex: 1400 }}
          >
            {cart}
          </div>
        ) : null}
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={classStyle.drawer}
          classes={{
            paper: classStyle.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
        {showCartClass === "Cart-opened" ? (
          <div
            className={`${showCartClass} minicart`}
            style={{ boxShadow: `1px 1px 10px black` }}
          >
            {cart}
          </div>
        ) : null}
      </Hidden>

      {renderMobileMenu}
      {renderMenu}
    </nav>
  );
}
export default React.memo(
  withTranslation(["message", "home", "navbar"])(NavBar)
);
