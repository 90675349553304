import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useThemeData } from '../context/ThemeDataContext';

// GeneralText is a reusable component for rendering text with custom styles
// based on the theme and the provided props.
//
// Props:
// - children: The text to be rendered inside the component.
// - className: The CSS class name to be applied to the component.
// - variant: The typography variant to be used.
// - color: The color of the text.
// - component: The HTML element to be used for the text.
// - gutterBottom: If true, adds a bottom margin to the text.
const GeneralText = (props) => {
  const {
    primary_color_1,
    primary_color_2,
    font_family_title,
    font_family,
  } = useThemeData().themeData.site.theme;

  const {
    children,
    className,
    variant,
    color,
    component,
    gutterBottom,
    ...other
  } = props;

  const isTitleVariant =
      ['h1', 'h2', 'h3', 'h5', 'h6', 'subtitle1', 'subtitle2'].includes(variant);

  const GeneralTextStyle = withStyles({
    root: {
      fontFamily: isTitleVariant ? font_family_title : font_family,
      color:
          color !== undefined
              ? color
              : variant === 'h3'
                  ? primary_color_2
                  : primary_color_1,
    },
  })(Typography);

  return (
      <GeneralTextStyle
          gutterBottom={gutterBottom}
          variant={variant}
          component={component}
          className={className}
          {...other}
      >
        {children}
      </GeneralTextStyle>
  );
};

export default React.memo(GeneralText);
