import React from 'react';
import Grid from '@material-ui/core/Grid';
import {withTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import {GET_ORDER_STATISTICS} from "../queries";
import {useThemeData} from "../context/ThemeDataContext";

function Dasboard(props) {
    const loginName = localStorage.getItem("login_name");
    const site_id = useThemeData().themeData.site.site_id;
    const firstPartLogin = loginName !== null ? loginName.replace('["', '') : '';
    const MiddelPartLogin = firstPartLogin.replaceAll('","', ', ');
    const login_name = MiddelPartLogin.replace('"]', '');
    const {loading: loadingPage, error: errorPage, data: dataPage} =
        useQuery(GET_ORDER_STATISTICS, {
            variables: {
                site_id: site_id,
                school_id: login_name,
                is_site: '1'
            },
            fetchPolicy: 'cache-and-network'
        });

    if (loadingPage) return <p>{props.t('Loading...')}</p>;
    if (errorPage) return <p>{props.t('Looks like we have a problem...')}</p>;
    if (!dataPage) return null;

    return (
        <Grid container direction="row" className="container page">
            {dataPage.orderStatistics.show_turnover === "1" && (
                <Grid item xs={12} style={{display: 'inline', textAlign: 'left'}}>
                    Dashboard
                    <br/>
                    De omzet van alle betaalde orders voor uw school
                    is: &euro; <strong>{dataPage.orderStatistics.bedrag_zonder_btw_verzendkosten_zonder}</strong> {dataPage.orderStatistics.bestellingen}
                </Grid>
                )}
        </Grid>
    );
}

export default React.memo(withTranslation('message')(Dasboard));
