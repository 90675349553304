export const formContent = {
    inputs: [
      {
        id:"klant_voornaam",
        name:"klant_voornaam",
        type:"text",
        autocomplete:"given-name",
        label: 'First Name',
        helperText: 'First Name text'
      },
      {
        id:"customer_preposition",
        name:"customer_preposition",
        type:"text",
        autocomplete:"given-name",
        label: 'Preposition',
      },
      {
        id:"klant_achternaam",
        name:"klant_achternaam",
        type:"text",
        autocomplete:"family-name",
        label: 'Last Name',
        helperText: 'Last Name text'
      },
      {
        id:"klant_email",
        name:"klant_email",
        type:"text",
        autocomplete:"email",
        label: 'Email',
        helperText: 'Email text'
      },
      {
        id:"klant_telnr",
        name:"klant_telnr",
        type:"text",
        autocomplete: 'tel',
        label: 'Phone number',
        helperText: 'Phone number text'
      },
      {
        id:"foreign",
        name:"foreign",
        type:"checkbox",
        autocomplete: undefined,
        label: 'Belgium',
        disabled: true
      },
      {
        id:"klant_postcode",
        name:"klant_postcode",
        type:"text",
        autocomplete: 'postal-code',
        label: 'Postal Code',
        helperText: 'Postal Code text',
        max:"8",
        min:"4"
      },
      {
        id:"klant_huisnummer",
        name:"klant_huisnummer",
        type:"text",
        autocomplete: 'address-line2',
        label: 'House Number',
        helperText: 'House Number text',
        min:"1"
      },
      {
        id:"klant_straat",
        name:"klant_straat",
        type:"text",
        autocomplete: 'address-line1',
        label: 'Street',
        helperText: 'Street text',
        min:"1"
      },
      {
        id:"klant_plaats",
        name:"klant_plaats",
        type:"text",
        autocomplete: 'address-line3',
        label: 'City',
        helperText: 'City text',
        min:"1"
      },
      {
        id:"klant_oudste_kind",
        name:"klant_oudste_kind",
        type:"text",
        autocomplete: undefined,
        label: 'Name oldest child',
        helperText: 'Name oldest child text',
      },
      {
        id:"klant_oudste_kind_klas",
        name:"klant_oudste_kind_klas",
        type:"select",
        autocomplete: undefined,
        label: 'Class oldest child',
      },
      {
        id: "shipping_urgent",
        name: "shipping_urgent",
        type: "select",
        autocomplete: undefined,
        label: "Shipping urgent"
      },
      {
        id: "shipping_method",
        name: "shipping_method",
        type: "select",
        autocomplete: undefined,
        label: "Shipping method"
      },
      {
        id:"opmerking",
        name:"opmerking",
        type:"text",
        autocomplete: undefined,
        label: 'Comments',
        helperText: 'Comments text',
        multiline: true,
        rows:3
      },
      {
        id: "downloads",
        name: "downloads",
        type: "checkbox",
        disabled: true,
        autocomplete: undefined,
        label: "Downloads"
      },
      {
        id: "oudste_kind",
        name: "oudste_kind",
        type: "checkbox",
        disabled: true,
        autocomplete: undefined,
        label: "oudste_kind"
      },
    ]
  }
