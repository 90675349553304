import { IconButton, TextField } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const QuantityControl = ({ primaryColor, backgroundColor, productId, quantity, changeQuantity }) => {
    // Ensure quantity is never less than 1 or more than 100
    const boundedQuantity = Math.min(100, Math.max(1, quantity || 1));

    return (
        <div className="quantity-field">
            <IconButton
                style={{
                    color: `${primaryColor}`,
                    backgroundColor: `${backgroundColor}`,
                    borderColor: `${backgroundColor}`,
                    padding: '0px'
                }}
                onClick={(event) =>
                    changeQuantity(event, productId, boundedQuantity - 1)
                }
                disabled={boundedQuantity <= 1}
            >
                <RemoveIcon />
            </IconButton>

            <TextField
                onChange={(event) => {
                    const value = Number(event.target.value);
                    // Ensure that the value is between 1 and 100
                    if (value >= 1 && value <= 100) {
                        changeQuantity(event, productId, value);
                    }
                }}
                value={boundedQuantity}
                InputProps={{
                    inputProps: {
                        max: 100,
                        min: 1,
                    },
                }}
                type="number"
            />

            <IconButton
                style={{
                    color: `${primaryColor}`,
                    backgroundColor: `${backgroundColor}`,
                    borderColor: `${backgroundColor}`,
                    padding: '0px'
                }}
                onClick={(event) =>
                    changeQuantity(event, productId, boundedQuantity + 1)
                }
                disabled={boundedQuantity >= 100}
            >
                <AddIcon />
            </IconButton>
        </div>
    );
};

export default QuantityControl;
