import React, {useEffect, useState} from "react";
// import ReactDOM from 'react-dom';
import {withTranslation} from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
// import {Card} from "@material-ui/core";
// import CardHeader from "@material-ui/core/CardHeader";
// import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";
// import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from '@material-ui/core/Grid';
// import Typography from "@material-ui/core/Typography";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
// import Tooltip from '@material-ui/core/Tooltip';
import {red} from "@material-ui/core/colors";
import ImageIcon from '@material-ui/icons/Image';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
// import ZoomInIcon from '@material-ui/icons/ZoomIn';
import {useThemeData} from "../../context/ThemeDataContext";
// import PopUpComponent from "../../components/Nav/PopUpComponent";
import ProductsContainer from "./productsContainer";
// import PhotoFilterComponent from "../../components/Photo/PhotoFilterComponent";
import "lightgallery.js/dist/css/lightgallery.css";
// import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';


// import {
//     // LightgalleryProvider,
//     LightgalleryItem,
//     // withLightgallery,
//     // useLightgallery
// } from "react-lightgallery";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    avatar: {
        backgroundColor: red[500],
        position: 'absolute',
    },
    zoomInIcon: {
        position: 'relative',
        color: 'white',
        bottom: '4px',
        right: '4px'
    },
    headPhotos: {
        width: "auto",
        maxWidth: "-webkit-fill-available",
        maxHeight: '80vh',
        padding: '1rem'
    },
    lightboxOverlay: {
        backgroundColor: 'rgba(255,255,255,0.8)'
    },

    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        overflow: 'hidden',
        height: '85%',
        backgroundColor: 'white',
        boxShadow: 24,
        padding: '20px',
        outline: 'none',
        borderRadius: '8px',
    },
    buttonContainerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px'
    },
    modalOverlay: {
        position: 'fixed',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.9)'
    }
}));


function srcset(
    image: string,
    width: number,
    height: number,
    rows = 1,
    cols = 1
) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${
            height * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

function PhotoCard(props) {
    const classes = useStyles();
    const {site_id} = useThemeData().themeData.site;
    const PHOTO_URL = process.env.REACT_APP_PHOTO_URL;
    const photo = props.data;
    const {
        selectedPhotos,
        updatePhotoSelection,
        disableSelection,
        selectedProducts,
        setSelectedProducts,
        addEffectToProduct,
        addQuantityToProduct,
        selectedEffect,
        selectedQuantities
    } = props;
    // const [open, setOpen] = useState(false);
    const [combivel_id, setCombivelId] = useState(0);
   // const [current_effect, setCurrentEffect] = useState();
    const [modalOpen, setModalOpen] = useState(false);
// Assuming this array exists in your component or is passed as a prop
 //   const photosArray = props.photos; // Replace with actual prop or state

    const CustomLightboxButton = ({onOpenModal}) => {
        return (
            <button
                style={{
                    background: 'white',
                    border: '1px solid black',
                    padding: '5px 10px',
                    cursor: 'pointer'
                }}
                onClick={onOpenModal}
            >
                Open Modal
            </button>
        );
    };

    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    // Function to open the lightbox
    const openLightbox = (index) => {
        setCurrentPhotoIndex(index);
        setIsLightboxOpen(true);
    };

    const handleOpenModal = () => {
        // Make sure photosArray is defined and has elements
        if (props.photos && props.photos.length > 0) {
            const index = props.photos.findIndex(p => p.id === photo.id);
            if (index !== -1) {
                setCurrentPhotoIndex(index);
                setModalOpen(true);
            }
        }
    };

    const handleNextPhoto = () => {
        setCurrentPhotoIndex((prevIndex) =>
            (prevIndex + 1) % props.photos.length // Loop back to the first photo after the last one
        );
    };

    const handleBackPhoto = () => {
        setCurrentPhotoIndex((prevIndex) =>
            (prevIndex - 1 + props.photos.length) % props.photos.length // Loop back to the last photo after the first one
        );
    };

    useEffect(() => {
        if (selectedProducts.length > 0) {
            let selectedProduct = selectedProducts.find(p => p.photo_id === photo.id && p.folder_id === photo.folder.folder_id);
            if (selectedProduct !== undefined) {
                setCombivelId(selectedProducts.find(p => p.photo_id === photo.id && p.folder_id === photo.folder.folder_id).productdetail.combivel_id);
            }
        } else {
            if (combivel_id !== 0) {
                setCombivelId(0)
            }
        }
    }, [selectedProducts, combivel_id, photo.folder.folder_id, photo.id])


    const handleCloseModal = () => {
        setModalOpen(false);
    };
    //
    // const PopupHandling = () => {
    //     closePopup()
    // }

    // const closePopup = () => {
    //     setOpen(!open);
    // };

    useEffect(() => {
        if (selectedEffect.find(f => f.photo_id === photo.id) !== undefined) {
         //   setCurrentEffect(selectedEffect.find(f => f.photo_id === photo.id).effect);
        } else {
         //   setCurrentEffect('Kleur');
        }
    }, [selectedEffect, photo.id])

    console.log(photo);

    const imageUrl = `${PHOTO_URL}${site_id}/${photo.folder.folder_id}/${combivel_id}/0/0/${photo.id}/${photo.file_name}`;
    //
    const cols = photo.id ? 2 : 1;
    const rows = photo.id ? 2 : 1;
    // const login = localStorage.getItem("login_name");

    useEffect(() => {
        console.log('Lightbox portal element:', document.getElementById('lightbox-portal'));
    }, [isLightboxOpen]);

    return (
        <ImageListItem cols={cols} rows={rows}>
            <img
                {...srcset(imageUrl, 250, 200, rows, cols)}
                alt={imageUrl}
                loading="lazy"
                onClick={() => openLightbox(props.photos.findIndex(p => p.id === photo.id))}
            />
            <ImageListItemBar
                sx={{
                    background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                title={'sss'}
                position="top"
                actionIcon={
                    localStorage.getItem("login_name") === 1 ?
                        <IconButton
                            aria-label="add to select"
                            onClick={() => updatePhotoSelection(photo)}
                            disabled={selectedPhotos.filter(f => f.id === photo.id).length === 0 && disableSelection === true}
                        >
                            {selectedPhotos.filter(f => f.id === photo.id).length === 1 ?
                                <ImageIcon htmlColor="#7ebd8c"/> : <AddPhotoAlternateIcon/>}
                        </IconButton>
                        :
                        <IconButton
                            aria-label="add to card"
                            // onClick={() => updatePhotoSelection(photo)}
                            onClick={handleOpenModal}
                            disabled={selectedPhotos.filter(f => f.id === photo.id).length === 0 && disableSelection === true}
                        >
                            {selectedPhotos.filter(f => f.id === photo.id).length === 1 ?
                                <ImageIcon htmlColor="#7ebd8c"/> : <AddShoppingCartIcon/>}
                        </IconButton>
                }
                actionPosition="left"
            />
            {isLightboxOpen && (
                <Lightbox
                    reactModalStyle={{ overlay: { backgroundColor: 'rgba(255, 255, 255, 0.9)' } }}
                    mainSrc={`${PHOTO_URL}${site_id}/${props.photos[currentPhotoIndex]?.folder.folder_id}/${combivel_id}/0/0/${props.photos[currentPhotoIndex]?.id}/${props.photos[currentPhotoIndex]?.file_name}`}
                    nextSrc={`${PHOTO_URL}${site_id}/${props.photos[(currentPhotoIndex + 1) % props.photos.length]?.folder.folder_id}/${combivel_id}/0/0/${props.photos[(currentPhotoIndex + 1) % props.photos.length]?.id}/${props.photos[(currentPhotoIndex + 1) % props.photos.length]?.file_name}`}
                    prevSrc={`${PHOTO_URL}${site_id}/${props.photos[(currentPhotoIndex - 1 + props.photos.length) % props.photos.length]?.folder.folder_id}/${combivel_id}/0/0/${props.photos[(currentPhotoIndex - 1 + props.photos.length) % props.photos.length]?.id}/${props.photos[(currentPhotoIndex - 1 + props.photos.length) % props.photos.length]?.file_name}`}
                    onCloseRequest={() => setIsLightboxOpen(false)}
                    onMovePrevRequest={handleBackPhoto}
                    onMoveNextRequest={handleNextPhoto}
                    toolbarButtons={[
                        <CustomLightboxButton onOpenModal={handleOpenModal}/>
                    ]}
                />
            )}
            <ProductsContainer
                data={photo}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                addEffectToProduct={addEffectToProduct}
                addQuantityToProduct={addQuantityToProduct}
                selectedEffect={selectedEffect}
                selectedQuantities={selectedQuantities}
            />

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={classes.modalOverlay}>
                    <div className={classes.modalStyle}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}> {/* Column for the photo */}
                                <img
                                    src={`${PHOTO_URL}${site_id}/${props.photos[currentPhotoIndex]?.folder.folder_id}/${combivel_id}/0/0/${props.photos[currentPhotoIndex]?.id}/${props.photos[currentPhotoIndex]?.file_name}`}
                                    alt="Product"
                                    style={{width: '100%', height: 'auto', borderRadius: '8px'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}> {/* Column for the ProductsContainer */}
                                <div>
                                    <h2 id="modal-title">Product Details</h2>
                                    <p id="modal-description">Select products and quantities.</p>

                                    <ProductsContainer
                                        data={photo}
                                        selectedProducts={selectedProducts}
                                        setSelectedProducts={setSelectedProducts}
                                        addEffectToProduct={addEffectToProduct}
                                        addQuantityToProduct={addQuantityToProduct}
                                        selectedEffect={selectedEffect}
                                        selectedQuantities={selectedQuantities}
                                    />

                                    <div className={classes.buttonContainerStyle}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleBackPhoto}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleCloseModal}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => updatePhotoSelection(photo)}
                                        >
                                            Update Selection
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleNextPhoto}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Modal>
        </ImageListItem>);
}

export default React.memo(withTranslation('shoppingcart')(PhotoCard));