import { NavLink } from "react-router-dom";

function Logo({ APP_URL, site_id, school_logo, dataPhotographer, fotograaf_id, logo }) {
    let logoSrc = `${APP_URL}${site_id}/theme/logos/${logo}`;
    let altText = "site logo";

    if (school_logo) {
        logoSrc = `${APP_URL}${site_id}/userfiles/school_logos/${school_logo}`;
        altText = "schoolfotograaf logo";
    } else if (fotograaf_id) {
        logoSrc = `${APP_URL}${site_id}/theme/logos/${dataPhotographer.photographer.afbeelding}`;
        altText = "fotograaf logo";
    }

    return (
        <NavLink exact to="/">
            <img src={logoSrc} className="navbar-brand-logo" alt={altText} />
        </NavLink>
    );
};

export default Logo;
