import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import SendIcon from '@material-ui/icons/Send';
import HomeIcon from '@material-ui/icons/Home';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { Grid, FormGroup, TextField } from '@material-ui/core';
import GeneralButton from '../Buttons';
import GeneralText from '../Fonts';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../../queries';

function ForgottenPasswordFormComponent(props) {
  const [email, setEmail] = useState('');
  const [alertStatus, setAlertStatus] = useState({
    success: { open: false, message: '' },
    error: { open: false, message: '' },
    severity: 'info',
  });

  const [forgotPassword, { loading: loadingForgotPassword }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      // Check the condition to determine if the message is a success
      const isSuccess = data.forgotPassword.status !== 'EMAIL_NOT_SENT';
      setAlertStatus((prevState) => ({
        ...prevState,
        open: true,
        severity: isSuccess ? 'success' : 'error',
        message: props.t(`forgot_password:${data.forgotPassword.message}`),
      }));
    },
    onError: (error) => {
      setAlertStatus((prevState) => ({
        ...prevState,
        open: true,
        message: error.message,
        severity: 'error',
      }));
    },
  });

  const emailRegex = new RegExp(
    '[^\\.\\s@:](?:[^\\s@:]*[^\\s@:\\.])?@[^\\.\\s@]+(?:\\.[^\\.\\s@]+)+(?:\\.[^\\.\\s@]+)*',
  );

  const isEmailValid = (email) => emailRegex.test(email);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setAlertStatus((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEmailValid(email)) {
      forgotPassword({
        variables: {
          client_id: 2, // Assuming these are constant and not sensitive
          client_secret: 'B4qLlm3bRViBRU6D0DNyxn0V9XtXzeLJV7iUHErt',
          email: email,
        },
      });
    }
  };

  if (loadingForgotPassword) return <p>{props.t('Loading...')}</p>;

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GeneralText variant="h3" gutterBottom>
            {props.t('register:Forgotten Password')}
          </GeneralText>
        </Grid>

        <Grid item xs={12}>
          <p>{props.t('register:Info')}</p>
        </Grid>

        <FormGroup controlid="formGridEmail">
          <TextField
            name="send_email_to"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            type="email"
            placeholder={props.t('register:Email')}
            variant="outlined"
            fullWidth
            required
            error={!isEmailValid(email) && email !== ''}
            helperText={!isEmailValid(email) && email !== '' ? 'Invalid email address' : ''}
          />
        </FormGroup>

        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6}>
            <GeneralButton onClick={props.history.goBack} startIcon={<HomeIcon />}>
              {props.t('register:Back')}
            </GeneralButton>
          </Grid>
          <Grid item xs={6}>
            <GeneralButton type="submit" endIcon={<SendIcon />}>
              {props.t('register:Send')}
            </GeneralButton>
          </Grid>
        </Grid>
      </Grid>

      {alertStatus && (
        <Snackbar
          anchorOrigin="top center"
          open={alertStatus.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertStatus.severity}>
            {alertStatus.message}
          </Alert>
        </Snackbar>
      )}
    </form>
  );
}

export default React.memo(
  withTranslation(['message', 'register', 'forgot_password'])(ForgottenPasswordFormComponent),
);
