import Typography from '@mui/material/Typography';
function ProductPrice({price, actionprice, from}) {
    // Function to format amount
    const commaSeparatedNumber = (amount) => {
        // Check if number is valid
        if (!isNaN(amount)) {
            // Convert number to comma-separated format and return
            // Convert amount to Euro currency format
            let formattedAmount = new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(amount);

            // Return with € symbol in front
            return formattedAmount;
        }

        // If not valid, return null or some fallback UI
        return null;
    };

    if ((from===undefined && (actionprice === null || actionprice === '0')) || (from!==undefined && price===actionprice)) {
        return (<>
            <Typography>
                {commaSeparatedNumber(price)}
            </Typography>
            </>
        )
    } else {
        if (from === undefined) {
            return (
                <><Typography>
                    Van <del>{commaSeparatedNumber(price)}</del> Voor {commaSeparatedNumber(actionprice)}
                </Typography>
                </>
            )
        } else {
            return (<>
                    <Typography>
                        <del>{commaSeparatedNumber(price)}</del>
                    </Typography>
                    <Typography>
                        {commaSeparatedNumber(actionprice)}
                    </Typography>
                </>
            )
        }
    }
};

export default ProductPrice;
