import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Grow from "@material-ui/core/Grow";
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackbarComponent(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar 
        open={true} 
        autoHideDuration={6000} 
        onClose={props.closeSnackbar}
        TransitionComponent={Grow}
        anchorOrigin={{ horizontal: 'center', vertical: 'center'}}
      >
        <Alert 
          onClose={props.closeSnackbar} 
          severity={props.severity}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
