import Alert from "@material-ui/lab/Alert";

function ProductDiscountAlert({ product, t }) {
    if (product.productdetail.korting_pakketten_aantal !== null && product.productdetail.korting_pakketten_perc !== null) {
        return (
            <Alert severity="warning" className="card-footer full-width-margin">
                {product.productdetail.korting_pakketten_aantal !== null ? `${t('shoppage:With a quantity of ')}${product.productdetail.korting_pakketten_aantal} ${t('shoppage:Or more of this package you will receive ')}` : null}
                {product.productdetail.korting_pakketten_perc !== null ? `${product.productdetail.korting_pakketten_perc}% ${t('shoppage:Discount')}` : null}
            </Alert>
        );
    }
    if (product.productdetail.quantum_aantal !== null && product.productdetail.quantum_perc !== null) {
        return (
            <Alert severity="warning" className="card-footer full-width-margin">
                {product.productdetail.quantum_aantal !== null ? `${t('shoppage:With a quantity of ')}${product.productdetail.quantum_aantal} ${t('shoppage:Or more of this product you will receive ')}` : null}
                {product.productdetail.quantum_perc !== null ? `${product.productdetail.quantum_perc}% ${t('shoppage:Discount')}` : null}
            </Alert>
        );
    }
    return null;
}

export default ProductDiscountAlert;
