import React, { useRef, useEffect } from "react";
import { Grid, Typography, Button, makeStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useThemeData } from "../../context/ThemeDataContext";

function CategorieComponent(props) {
  const { site_id, font_family, primary_color_7, primary_color_6, background_color_2 } = useThemeData().themeData.site;
  const { setCategoryID } = useThemeData().categoryData;
  const history = useHistory();

  useEffect(() => {
    if (props.categoryData.length === 1) {
      // Redirect to the single category
      const category = props.categoryData[0];
      setCategoryID(category.categorie_id);
      history.push(`/shoppage/photo_id/${props.select_photo_id}/${category.categorie_id}`);
    } else if (props.categoryData.length > 0) {
      // Set the first category as active if there are multiple categories
      setCategoryID(props.categoryData[0].categorie_id);
    }
  }, [props.categoryData, props.select_photo_id, setCategoryID, history]);

  const scrollRef = useRef(null);

  const useStyles = makeStyles(() => ({
    catLink: {
      color: `${primary_color_7} !important`,
      "&:active": {
        color: `${primary_color_6} !important`,
      },
      "&:hover": {
        color: `${primary_color_6} !important`,
      },
    },
  }));

  const classes = useStyles();
  const APP_URL = process.env.REACT_APP_SITE_URL;

  const { changeData, type } = props;

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop + 400);

  if (type === "homePrintService") {
    return renderHomePrintService();
  } else if (type === "sidebar") {
    return renderSidebar();
  }

  return null;

  function renderHomePrintService() {
    return (
        <Grid container className="CategorieComponent" style={{ fontFamily: `${font_family}`, marginTop: `-20px` }}>
          {props.categoryData.map((category) => (
              <Grid item xs={12} sm={6} md={4} key={category.categorie_id}>
                <Button
                    style={{ float: "center" }}
                    className="photos row image-link"
                    value={category.categorie_id}
                    onClick={(e) => changeData(e, "routeTwo", category.naam)}
                >
                  {category.afbeelding && (
                      <Grid
                          item
                          xs={12}
                          xl={12}
                          className="cat-box"
                          style={{
                            backgroundImage: `url(${APP_URL}${site_id}/userfiles/category/${category.afbeelding})`,
                          }}
                      >
                        <div className="cat-image-overlay">
                          <div className="cat-image-text" style={{ backgroundColor: `${background_color_2}` }}>
                            {category.naam}
                          </div>
                        </div>
                      </Grid>
                  )}
                </Button>
              </Grid>
          ))}
        </Grid>
    );
  }

  function renderSidebar() {
    return (
        <Grid item xs={12} sm={12} className="card no-padding-sides">
          {props.categoryData.map((category) => {
            if (category.naam !== "") {
              const active =
                  window.location.pathname === `/shoppage/photo_id/${props.select_photo_id}/${category.categorie_id}`
                      ? "active"
                      : "";

              return (
                  <div key={category.categorie_id}>
                    <Grid item xs={12} sm={12} className="top-col no-padding-sides">
                      <Link
                          style={{ float: "center" }}
                          to={`/shoppage/photo_id/${props.select_photo_id}/${category.categorie_id}`}
                          className={`${active} photos row image-link`}
                          onClick={() => {
                            scrollToRef(scrollRef);
                            setCategoryID(category.categorie_id);
                          }}
                      >
                        {category.afbeelding ? (
                            <Grid item xs={12} sm={12} md={12} className="no-padding-sides">
                              <div
                                  className="cat-btn"
                                  style={{
                                    backgroundImage: `url(${APP_URL}${site_id}/userfiles/category/${category.afbeelding})`,
                                  }}
                              >
                                <div className="cat-image-overlay">
                                  <div className={`${classes.catLink} cat-image-text`}>
                                    <Typography className="font-bold">{category.naam}</Typography>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={12} md={12} className="no-padding-sides">
                              <ul>
                                <li
                                    className={`${classes.catLink} text-start`}
                                    style={{
                                      background: `url('https://cdn4.iconfinder.com/data/icons/spirit20/camera.png') no-repeat left top`,
                                      padding: `0px 0px 0px 23px`,
                                      listStyle: `none`,
                                      margin: `0`,
                                    }}
                                >
                                  {category.naam}
                                </li>
                              </ul>
                            </Grid>
                        )}
                      </Link>
                    </Grid>
                  </div>
              );
            }
            return null;
          })}
          <div ref={scrollRef}></div>
        </Grid>
    );
  }
}

export default React.memo(withTranslation("message")(CategorieComponent));
