import React from 'react';
import { Grid } from '@material-ui/core';
import PhotoItem from './PhotoItem';
import GeneralText from "../Fonts";
import { useThemeData } from "../../context/ThemeDataContext";

const PhotoGridHome = ({ photos, selectPhoto, siteId, backgroundColors, borderRadius, changePhoto, t }) => {
  const { primary_color_1 } = useThemeData().themeData.site.theme;

  // Utility function for border styles
  const getBorderStyle = (color, borderRadius) => ({
    border: color != null ? `1px solid ${color}` : "1px solid rgb(217 218 219)",
    borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} ${borderRadius}`,
  });

  // Utility function for header styles
  const getHeaderStyle = (backgroundColor, borderRadius) => ({
    backgroundColor: backgroundColor,
    borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
    color: primary_color_1,
  });

  // Photo header component
  const PhotoHeader = ({ title, backgroundColors, borderRadius }) => (
    <Grid item xs={12} sm={12} md={12} style={getHeaderStyle(backgroundColors.color7, borderRadius)}
          className="bottom-card">
      <GeneralText variant="h6" className="text-start">
        {title}
      </GeneralText>
    </Grid>
  );

  const renderPhotoItems = (type) => {
    return photos
      .filter(photo => photo.type === type)
      .sort((a, b) => {
        if (a.login_name < b.login_name) return -1;
        if (a.login_name > b.login_name) return 1;
        return 0;
      })
      .map((photo, index) => (
        <PhotoItem
          key={photo.id}
          photo={photo}
          selected={selectPhoto === photo.id}
          siteId={siteId}
          changePhoto={changePhoto}
          backgroundColors={backgroundColors}
          borderRadius={borderRadius}
          t={t}
        />
      ));
  };

  const portraitPhotos = renderPhotoItems("portret");
  const groupPhotos = renderPhotoItems("groep");

  return (
    <Grid container>
      {/* Conditionally render Portrait photos */}
      {portraitPhotos.length > 0 && (
        <Grid item xs={12} className="image-overview-masonry image-overview-masonry-bottom-margin"
              style={getBorderStyle(backgroundColors.color7, borderRadius)}>
          <PhotoHeader title={t("photosComponent:Portrait photo(s)")} backgroundColors={backgroundColors}
                       borderRadius={borderRadius}/>
          {portraitPhotos}
        </Grid>
      )}

      {/* Conditionally render Group photos */}
      {groupPhotos.length > 0 && (
        <Grid item xs={12} className="image-overview-masonry"
              style={getBorderStyle(backgroundColors.color7, borderRadius)}>
          <PhotoHeader title={t("photosComponent:Class photo(s)")} backgroundColors={backgroundColors}
                       borderRadius={borderRadius}/>
          {groupPhotos}
        </Grid>
      )}
    </Grid>
  );
};

export default PhotoGridHome;
