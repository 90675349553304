import * as yup from 'yup'

const password_validation_message = "SafePassword";
const password_regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})";
const empty_field = "EmptyField"

export const RegistrationSchema = yup.object().shape({
    email: yup.string().required(empty_field).email("ErrorEmail"),
    password: yup.string().required(empty_field).matches(password_regex, password_validation_message),
    password_validation: yup.string().required(empty_field).matches(password_regex, password_validation_message).oneOf([yup.ref('password'), null], 'ValidateError'),
});

export const TicketRegistrationSchema = yup.object().shape({
  login_name: yup.string().required(empty_field),
  password: yup.string().required(empty_field),
})

export const LoginSchema = yup.object().shape({
  users: yup.array().ensure().of(
    yup.object().shape({
      login_name: yup.string().required(empty_field),
      login_password: yup.string().required(empty_field),
    })).required(empty_field)
  // loginOrCreate: yup.boolean().oneOf([0,1], 'select login or create an account'),
});

export const ContactSchema = yup.object().shape({
  firstname_sender: yup.string().required(empty_field),
  lastname_sender: yup.string().required(empty_field),
  email_sender: yup.string().required(empty_field).email("ErrorEmail"),
  subject: yup.string().required(empty_field),
  message: yup.string().required(empty_field),
})
