import React from 'react';
import Helmet from 'react-helmet';

// SeoComponent is a reusable component for rendering SEO-related HTML tags
// and metadata in the document head.
//
// Props:
// - siteName: The name of the website.
// - favicon: The URL of the favicon image.
// - title: The title of the current page.
// - description: A brief description of the current page.
const SeoComponent = ({ site_name, favicon, title, description }) => {
    const defaultTitle = 'Fotoprotools';

    return (
        <Helmet>
            <title>{`${site_name} - ${title ? title : defaultTitle}`}</title>
            <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
            <link rel="apple-touch-icon" href={favicon} />
            <link rel="shortcut icon" type="image/jpg" href={favicon} />
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${site_name} - ${title ? title : defaultTitle}`} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content={site_name} />
            <meta property="og:image" content={favicon} />
        </Helmet>
    );
};

export { SeoComponent };
