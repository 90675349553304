import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaymentIcon from '@material-ui/icons/Payment';
import RedeemIcon from '@material-ui/icons/Redeem';
import Badge from '@material-ui/core/Badge';
import { Grid, Typography } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "-webkit-fill-available"
  },
  grow: {
    flexGrow: 1
  },
  subText: {
    marginLeft: '1rem'
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
}));

export default function UserToolBar(props) {
    const classes = useStyles();
    const {credits, totalCredits, addCredits, addCreditsToCart} = props;
    
  return (

    <Grid item xs={12} md={12} lg={12} className={classes.grow}>
    <AppBar position="static" color={'inherit'}>
      <Toolbar>
        <Typography className={classes.title} variant="h6" >
          Credits
        </Typography>
        <Typography className={classes.subText} variant="caption">
          U heeft een maximale hoeveelheid credits welke u kunt besteden aan fotos. Wilt u meer fotos selecteren? 
          U kunt extra credits kopen door deze aan de winkelmand toe te voegen.
        </Typography>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <Tooltip title="Credits totaal" placement="bottom">
            <IconButton aria-label="show credits total" color="inherit">
              <Badge badgeContent={totalCredits} overlap="circle" color="secondary" showZero>
                <AccountBalanceIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Credits te besteden" placement="bottom">
            <IconButton aria-label="show credits left to spend" color="inherit">
              <Badge badgeContent={credits} overlap="circle" color="secondary" showZero>
                <PaymentIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="5 Credits kopen" placement="bottom">
            <IconButton aria-label="add to shopping cart" color="inherit" onClick={() => addCreditsToCart()}>
              <Badge badgeContent={addCredits} overlap="circle" color="secondary" showZero>
                <RedeemIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  </Grid>

  );
}
