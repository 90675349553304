export const formContent = {
    inputs: [
      {
        name:"email",
        type:"text",
        autocomplete:"username",
        label: 'Email',
      },
      {
        name:"password",
        type: "password",
        autocomplete:"new-password",
        label: 'Password',
      }, 
      {
        name:"password_validation",
        type:"password",
        autocomplete:"new-password",
        label:'Password validation',
      }
  ]
  }