import React from "react";
import { withTranslation } from 'react-i18next';
import { Grid, TextField } from "@material-ui/core";
import { useEffect } from "react";

function ProductEffect(props) {
  const {data, addEffectToProduct, selectedEffect, effecten} = props;
  const [current_effect, setCurrentEffect] = React.useState('null');

  useEffect(() => {
    if (selectedEffect.length > 0 && JSON.stringify(selectedEffect) !== JSON.stringify(current_effect) ) {
      if (selectedEffect.find(p => p.photo_id === data.id) !== undefined) {
        setCurrentEffect(selectedEffect.find(p => p.photo_id === data.id).effect)
      }
    }
  }, [selectedEffect, data.id, current_effect])

  return (
    <Grid container direction="row">
      <TextField
        type="select"
        key={data.length !== 0 ? data.id : 0}
        id={data.length !== 0 ? data.id : 0}
        select
        SelectProps={{ native: true }}
        label={props.t("products:Effect Choice")}
        value={current_effect}
        onChange={(e) =>
          addEffectToProduct(e, effecten.product_id, data.id)
        }
        InputLabelProps={{ shrink: true }}
        className="mb-2"
        variant="standard"
        fullWidth
      >
          <option id={0} value={"null"}>
            {props.t("products:Effect")}
          </option>
         {effecten.effecten.length !== 0 && effecten.effecten.map((p, i) => ( 
          <option id={i} key={i} value={p} >
            {p}
          </option>
         ))} 
    </TextField>
    </Grid>
  );
}

export default React.memo(withTranslation('products')(ProductEffect));