import React, {useEffect} from "react";
import { withTranslation } from 'react-i18next';
import { Grid } from "@material-ui/core";
import Products from './products';
import ProductEffects from './productEffects';
import ProductAmount from './productAmount';

function ProductsContainer(props) {
  const {data, selectedProducts, setSelectedProducts, addEffectToProduct, addQuantityToProduct, selectedEffect, selectedQuantities} = props;

  const [product, setProduct] = React.useState({product_id: "null"});
  const [effecten, setEffecten] = React.useState({product_id: null, effecten: []});

  useEffect(() => {
    const currentProductID = product.product_id;
    if (currentProductID !== "null") {
      if (data.folder.productlist.products.find(i => i.product_id === currentProductID) !== undefined){
        const currentProduct = data.folder.productlist.products.find(i => i.product_id === currentProductID)
        let new_effect_object = {            
          product_id: currentProductID,
          effecten: []
        };
        if (currentProduct.productdetail.kleuren !== '' && currentProduct.productdetail.kleuren !== null) {
          new_effect_object = {
            product_id: currentProductID,
            effecten: currentProduct.productdetail.kleuren.split(';')
          }
          setEffecten(new_effect_object);
        } else {
          new_effect_object = {
            product_id: currentProductID,
            effecten: []
          }
          setEffecten(new_effect_object);
        }
      }
    } 
  }, [product, data])

  return (
    <Grid container direction="row">
      <Grid item sm={12} md={6} lg={6}>
        <Products 
          data={data} 
          selectedProducts={selectedProducts} 
          setSelectedProducts={setSelectedProducts}
          setProduct={setProduct}
          product={product}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        <ProductEffects
          data={data} 
          selectedProducts={selectedProducts} 
          addEffectToProduct={addEffectToProduct}
          selectedEffect={selectedEffect}
          product={product}
          effecten={effecten}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        product backgrounds
      </Grid>
      <Grid item sm={12} md={6} lg={6}>
        <ProductAmount
          data={data} 
          selectedProducts={selectedProducts} 
          addQuantityToProduct={addQuantityToProduct}
          selectedEffect={selectedEffect}
          product={product}
          effecten={effecten}
          selectedQuantities={selectedQuantities}
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(withTranslation('products')(ProductsContainer));