import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { useThemeData } from '../context/ThemeDataContext';

const GeneralButton = (props) => {
  const {
    border_radius,
    primary_color_4,
    background_color_2,
    font_family_button,
  } = useThemeData().themeData.site.theme;
  const { children, className, onClick, to, ...other } = props;

  const GeneralButtonStyle = withStyles({
    root: {
      fontFamily: `${font_family_button}`,
      color: `${primary_color_4}`,
      backgroundColor: `${background_color_2}`,
      borderColor: `${background_color_2}`,
      borderRadius: `${border_radius}`,
    },
  })(Button);

  const ButtonComponent = to ? RouterLink : 'button';

  return (
    <GeneralButtonStyle
      component={ButtonComponent}
      {...(to ? { to } : {})} // conditionally spreading the `to` prop
      variant="contained"
      className={className}
      onClick={onClick && (props.name === 'submit' ? onClick.onclick : onClick)}
      {...other}
    >
      {children}
    </GeneralButtonStyle>
  );
};

export default React.memo(GeneralButton);
