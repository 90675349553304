import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PaymentIcon from '@material-ui/icons/Payment';
import { useThemeData } from '../../context/ThemeDataContext'

function ColorlibConnector(props) {
  const {font_family_button, primary_color_2, primary_color_4, background_color_7, background_color_2} = useThemeData().themeData.site.theme;
  const { children, className, ...other } = props;
  const ColorlibConnectorStyle = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        fontFamily: `${font_family_button}`,
        color: `${primary_color_2}`,
        backgroundColor: `${background_color_7}`,
        borderColor: `${background_color_2}`,
      },
    },
    completed: {
      '& $line': {
        fontFamily: `${font_family_button}`,
        color: `${primary_color_2}`,
        backgroundColor: `${background_color_7}`,
        borderColor: `${background_color_2}`,
      },
    },
    line: {
      height: 3,
      border: 0,
      fontFamily: `${font_family_button}`,
      color: `${primary_color_4}`,
      backgroundColor: `${background_color_2}`,
      borderColor: `${background_color_2}`,
      borderRadius: 1,
    },
  })(StepConnector);

  return (
    <ColorlibConnectorStyle {...other} className={className}>
      {children}
    </ColorlibConnectorStyle>
  );
}

function ColorlibStepIcon(props) {
  const {font_family_button, primary_color_2, primary_color_4, background_color_7, background_color_2} = useThemeData().themeData.site.theme;
  const useColorlibStepIconStyles = makeStyles({
    root: {
      fontFamily: `${font_family_button}`,
      color: `${primary_color_4}`,
      backgroundColor: `${background_color_2}`,
      borderColor: `${background_color_2}`,
      borderRadius: `0.25rem`,
      zIndex: 1,
      width: 100,
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      fontFamily: `${font_family_button}`,
      color: `${primary_color_2}`,
      backgroundColor: `${background_color_7}`,
      borderColor: `${background_color_2}`,
      borderRadius: `0.25rem`,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        fontFamily: `${font_family_button}`,
        color: `${primary_color_2}`,
        backgroundColor: `${background_color_7}`,
        borderColor: `${background_color_2}`,
        borderRadius: `0.25rem`,
    },
    disabled: {
      pointerEvents: 'none',
      background: 'grey',
    }
  });

  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  let location = '0';
  const icons = {
    1: <HomeIcon />,
    2: <ShoppingCartIcon />,
    3: <ImportContactsIcon />,
    4: <PaymentIcon />,
  };


  switch(props.icon) {
    case 2:
      location = '/cart';
    break;
    case 3:
      location = '/addressform';
    break;
    case 4:
      location = '/addressform';
    break;
    default:
      location = '/';
      break;
  }

  return (
    <NavLink
      exact to={location}
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.disabled]: (props.icon === 4 || props.icon === 3) && !active
      })}
    >
      {icons[String(props.icon)]}
    </NavLink>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};


function CustomizedSteppers(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [props.t('Back to Home'), props.t('Shoppingcart'), props.t('Address form'), props.t('Payment Method')];
  
  const refs = React.useRef(steps.map(() => React.createRef()));

  React.useEffect(() => {
    refs.current[props.activeStep].current.scrollIntoView({
      block: "center",
      inline: "center" 
    })
  });

  React.useEffect(() => {
      switch (props.activeStep) {
        case '1':
        setActiveStep(1)
        break;
        case '2':
        setActiveStep(2)
        break;
        case '3':
        setActiveStep(3)
        break;
        default:
        setActiveStep(0)
        break;
      }
    },[props.activeStep])

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      style={{ backgroundColor: "transparent", height: "100px" }}
      className="cart-wizzard"
      connector={<ColorlibConnector />}
    >
      {steps.map((label, i) => (
        <Step key={label} ref={refs.current[i]}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
export default withTranslation('cartRouteComponent')(CustomizedSteppers);
