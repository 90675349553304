import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

export default function SearchBar(props) {
    const classes = useStyles();
    const [search, setSearch] = useState('');

    function changeValue(e) {
        const {value} = e.target;
        setSearch(value);
        if (value === '') {
          props.handleChange(e, '')
        }
    }

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        value={search}
        placeholder="Zoeken"
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => changeValue(e)}
      />
      <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={(e) => props.handleChange(e, search)}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
