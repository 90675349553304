import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';

const ProductPrintType = ({ productPrintType, handlePrintType }) => {
  const [selectedProperties, setSelectedProperties] = useState('');

  useEffect(() => {
    if (productPrintType && productPrintType.length > 0 && selectedProperties === '') {
      const initialSelection = productPrintType[0].name;
      setSelectedProperties(initialSelection); // Set the initial selection
      handlePrintType(initialSelection);       // Notify parent component immediately
    }
  }, [productPrintType, selectedProperties, handlePrintType]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedProperties(selectedValue); // Update the state with the selected value
    handlePrintType(selectedValue);       // Notify parent component of change
  };

  // Render the select box only if there are multiple options available
  if (!productPrintType || productPrintType.length === 0) {
    return null; // Return null or an alternative component or message if there are no types
  } else if (productPrintType.length === 1) {
    return null; // Do not render the dropdown if only one type is available
  }

  return (
    <Box>
      <FormControl fullWidth margin="normal">
        <InputLabel>Kies je afdruk typen</InputLabel>
        <Select
          value={selectedProperties}
          onChange={handleChange}
          label="Kies je afdruk typen"
        >
          {productPrintType.map(type => (
            <MenuItem key={type.name} value={type.name}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ProductPrintType;
