import React, {useState} from "react";
import { withTranslation } from 'react-i18next';

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useThemeData } from "../../context/ThemeDataContext";
import PopUpComponent from "../../components/Nav/PopUpComponent";
import GeneralButton from "../../components/Buttons";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: "transparent",
  },
  img: {
    height: "auto",
    display: "block",
    maxWidth: 400,
    overflow: "hidden",
    width: "auto",
    maxHeight: "34vh",
  },
  headPhoto: {
    height: "28vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    border: "solid 1px #707070",
  },
  headPhotos: {
    width: "auto",
    maxWidth: "-webkit-fill-available",
    maxHeight: '80vh',
    padding: '1rem',
  },
  gridCol: {
    placeSelf: 'center'
  },
  thumbimg: {
    minHeight: "9vh",
    width: '-webkit-fill-available',
    minWidth: "12vw",
    maxHeight: "9vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    margin: "0px 5px",
    cursor: "pointer",
  },
  footer: {
    backgroundColor: "transparent",
    display: "flex",
    padding: "0.5rem 0rem",
    justifyContent: "center",
    flexWrap: "nowrap",
  },
  footerObjects: {
    overflowY: "auto",
    width: '-webkit-fill-available',
  },
  buttons: {
    minWidth: "25px",
  },
  buttonContainer: {
    placeSelf: 'center',
  },
  container:{
    textAlign: 'center'
  }
}));

const PHOTO_URL = process.env.REACT_APP_PHOTO_URL;

function PhotoCarousel(props) {
  const { site_id } = useThemeData().themeData.site;
  const { background_color_7, primary_color_2 } = useThemeData().themeData.site.theme;
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = props.photos.length;
  const photos = props.photos;
  const loadingPhotos = props.loadingPhotos;
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(undefined);

  const PopupHandling = (id, index) => {
    const first_photo = index;
    setSelectedIndex(first_photo);
    closePopup()
  }

  const closePopup = () => {
    setOpen(!open);
  };

  const handlePopUpNext = () => {
    const newIndex = selectedIndex + 1;
    setSelectedIndex(newIndex);
    changePhoto(newIndex)
    handleNext()
  };

  const handlePopUpBack = () => {
    const newIndex = selectedIndex - 1;
    setSelectedIndex(newIndex);
    changePhoto(newIndex)
    handleBack()
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleMouseDown = e => {
    e.preventDefault();
  }

  const changePhoto = (index) => {
    setActiveStep(index);
    const type = "carousel";
    props.changePhoto(photos[index].id, photos[index].folder.folder_id, type);
  };

  return (
    <div className={`${classes.root} headphoto_swipe`}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={photos.findIndex((f) => f.id === props.selected_photo_id)}
        onChangeIndex={handleStepChange}
        enableMouseEvents={false}
        autoplay={false}
        style={{ textAlign: "-webkit-center" }}
        slideStyle={{width: "-webkit-fill-available"}}
      >
        {loadingPhotos ? (
          <LinearProgress
            className="align-self-center"
            style={{ color: primary_color_2 }}
          />
        ) : (
          photos.map((photo, index) => {
            if (index === selectedIndex) {
              return (<div
                key={photo.id}
                className={`${classes.headPhoto} headphoto`}
                onClick={() => PopupHandling(photo.id, index)}
                style={{
                  backgroundImage: `url(${PHOTO_URL}${site_id}/${photo.folder.folder_id}/0/0/0/0/${photo.id}/${photo.file_name})`,
                  cursor: 'zoom-in'
                }}
              />)
            } else {
              return (<div
                key={photo.id}
                className={`${classes.headPhoto} headphoto`}
                onClick={() => PopupHandling(photo.id, index)}
                style={{
                  backgroundImage: `url(${PHOTO_URL}${site_id}/${photo.folder.folder_id}/0/0/0/0/${photo.id}/${photo.file_name})`,
                  cursor: 'zoom-in'
                }}
              />)
            }
          })
        )}
      </AutoPlaySwipeableViews>
      {open === true &&
      <PopUpComponent
        closePopup={closePopup}
        content={
          photos.map((photo, index) => (
            index === selectedIndex &&
            <Grid container direction="row" className={classes.container}>
              <Grid item xs={1} className={classes.gridCol}>
                <GeneralButton size="small" onClick={handlePopUpBack} disabled={index === 0} className={classes.buttons}>
                  <KeyboardArrowLeft />
                </GeneralButton>
              </Grid>
              <Grid item xs={10} onContextMenu={(e)=> handleMouseDown(e)}>
                <img
                    src={`${PHOTO_URL}${site_id}/${photo.folder.folder_id}/0/0/0/0/${photo.id}/${photo.file_name}`}
                    onClick={() => closePopup()}
                    className={classes.headPhotos}
                    alt={photo.file_name}
                    style={{maxWidth: 'fit-content'}}
                />
              </Grid>
              <Grid item xs={1} className={classes.gridCol}>
                <GeneralButton size="small" onClick={handlePopUpNext} disabled={index === photos.length - 1} className={classes.buttons}>
                  <KeyboardArrowRight />
                </GeneralButton>
              </Grid>
            </Grid>
          ))
        }
        btn2={props.t('shoppingcart:Continue Shopping')}
      />
      }
      <Grid container direction="row" className={`${classes.footer} grid-row`}>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.buttonContainer}>
        <GeneralButton
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          className={classes.buttons}
        >
            <KeyboardArrowLeft />
        </GeneralButton>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <Grid container direction="row"
          className={`${classes.footerObjects} second`}
            index={activeStep}
            onChangeIndex={handleStepChange}
            style={{flexWrap: 'nowrap', overflow: "hidden"}}
          >
            {loadingPhotos ? (
              <LinearProgress
                className="align-self-center"
                style={{ color: primary_color_2 }}
              />
            ) : (
              photos.map((photo, index) =>
                Math.abs(activeStep - index) <= 1  ? (
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4} style={{display: 'table', width: '-webkit-fill-available', overflow: 'hidden'}}>
                   <div
                    onClick={() => changePhoto(index)}
                    key={photo.id}
                    className={`${classes.thumbimg} thumb`}
                    style={{
                      backgroundImage: `url(${PHOTO_URL}${site_id}/${photo.folder.folder_id}/0/0/0/0/${photo.id}/${photo.file_name})`,
                      boxShadow:
                          activeStep === index
                              ? `0px 15px 10px 2px ${background_color_7}`
                              : "none",
                    }}
                  />
                  </Grid>
                ) : null
              )
            )}
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} className={classes.buttonContainer}>
        <GeneralButton
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          className={classes.buttons}
        >
            <KeyboardArrowRight />
        </GeneralButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default React.memo(withTranslation('shoppingcart')(PhotoCarousel));
