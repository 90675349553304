import React from "react";
import { withTranslation } from 'react-i18next';
import { Grid, TextField } from "@material-ui/core";

function Products(props) {
  const {data, selectedProducts, setSelectedProducts, product, setProduct} = props;

  async function UpdateProduct(e) {
    const indexToRemove = selectedProducts.findIndex(f => f.photo_id === data.id && f.folder_id === data.folder.folder_id);
    const productToRemove = selectedProducts.find(f => f.photo_id === data.id && f.folder_id === data.folder.folder_id);

    let new_selected_products = selectedProducts;

    async function handleProduct(index, product = null) {
      if (index === -1 && product !== null) {
          //case one: new product has been chosen for this photo: add item
          let newSelectedProducts = new Set(new_selected_products);
          newSelectedProducts.add(...product);
          new_selected_products = Array.from(newSelectedProducts)
          return new_selected_products;
      } else if (index !== -1 && product === null) {
        //case two: "selection got canceled" for this photo, delete the previous product that was chosen for this photo
          new_selected_products = new_selected_products.filter((p, i) => i !== index);
          return new_selected_products;
      } else if (index !== -1 && product !== null ) {
        //case three: a new product has been chosen for this photo, delete the previous by replacing it with the new one.
        let newSelectedProducts = new Set(new_selected_products);
        newSelectedProducts.delete(productToRemove);
        newSelectedProducts.add(...product);
        new_selected_products = Array.from(newSelectedProducts)
        return new_selected_products;
      } else {
        return false;
      }
    }

    if (e.target.value !== "null") {
      let newProduct = new Set();
      const currentProduct = data.folder.productlist.products.find(f => f.product_id === e.target.value);

      const new_data = {
        ...currentProduct,
        photo_id: data.id,
        folder_id: data.folder.folder_id
      }
      newProduct.add(new_data);
      const new_product = Array.from(newProduct)
      setProduct(new_product[0]);

      return new Promise((resolve) => {
        resolve(handleProduct(indexToRemove, new_product))
      }).then((d) => {
        return setSelectedProducts(d);
      }).catch((error) => {
        console.error(error)
      })
    } else {
      return new Promise((resolve) => {
        setProduct({product_id: null});
        resolve(handleProduct(indexToRemove));
      }).then((d) => {
        return setSelectedProducts(d);
      }).catch((error) => {
        console.error(error)
      })
    }
  }

  return (
    <Grid container direction="row">
      <TextField
        type="select"
        key={data.length !== 0 ? data.id : 0}
        id={data.length !== 0 ? data.id : 0}
        select
        SelectProps={{ native: true }}
        label={props.t("products:Product Choice")}
        value={product.product_id}
        onChange={(e) =>
          UpdateProduct(e)
        }
        InputLabelProps={{ shrink: true }}
        className="mb-2"
        variant="standard"
        fullWidth
      >
          <option id={0} value={"null"}>
            {props.t("products:Pick a product")}
          </option>
         {data.length !== 0 && data.folder.productlist.products.map((p) => ( 
          //  p.productdetail.productcategory.naam 
          <option id={p.product_id} key={p.product_id} value={p.product_id} >
            {p.productdetail.naam}
          </option>
         ))} 
    </TextField>
    </Grid>
  );
}

export default React.memo(withTranslation('products')(Products));