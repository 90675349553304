import React, { useState, useEffect } from 'react';
import { FormGroup, FormLabel, Select, MenuItem } from '@material-ui/core';

const ProductColorSelector = (props) => {
    // Get colors of product and select default for dropdown menu
    const colors = props.product_colors !== null && props.product_colors !== '' ? props.product_colors.split(";") : null;
    let initialColor = 'Kleur';
    if (colors !== null) {
        if (colors.length === 1) {
            initialColor = colors[0];
        }
    }

    const [product_color, setProductColor] = useState(initialColor);
    const [show, setShow] = useState("");

    useEffect(() => {
        if (!props.product_colors || props.product_colors === null || props.product_colors === '') {
            setShow("hide");
        } else {
            setShow("show");
        }
    }, [props.product_colors]);

    const handleColorChangePhoto = (event) => {
        setProductColor(event.target.value);
        props.handleColorChangePhoto(event);
    };

    if (props.photo_file_name && props.photo_file_name.startsWith('ZW')) {
        return null;
    }

    return (
        colors !== null && (
            <FormGroup
                style={{
                    width: `98%`,
                }}
            >
                <FormLabel className={show}>
                    {props.t("shoppingcart:Image Effect")}
                </FormLabel>
                <Select
                    className={show}
                    value={product_color} // Use `value` instead of `defaultValue`
                    onChange={(event) => {
                        props.handleColorChange(event);
                        handleColorChangePhoto(event);
                    }}
                >
                    {colors.map((color) => (
                        <MenuItem
                            key={color}
                            id={props.product_id}
                            value={color}
                            className="Select-options"
                        >
                            {color}
                        </MenuItem>
                    ))}
                </Select>
            </FormGroup>
        )
    );
};

export default ProductColorSelector;
