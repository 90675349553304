export const formContent = {
    inputs: [
      {
        id:"login_name",
        name:"login_name",
        type:"text",
        autocomplete:"username",
        label: 'Login Name',
      },
      {
        id:"password",
        name:"password",
        type:"password",
        autocomplete: undefined,
        label: 'Password',
      },
    ]
  }